import { Injectable } from '@angular/core';
import { Observable, first, forkJoin, of, switchMap, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { RecurlyRepository } from '../../core/domain/recurly/repositories/recurly.repository';
import { CardToken } from '../../core/domain/recurly/models/CardToken';
import {
    CardSchema,
    CreditCardTypeCardBrandId,
} from '../../core/domain/recurly/models/CardSchema';
import creditCardType from 'credit-card-type';
import { environment } from '@/env/environment';
import { PurchaseCustomModel } from '@/core/domain/recurly/models/PurchaseCustomModel';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { CheckLoginUseCase } from '@/core/domain/auth/usecases/check-login.usecase';
import { CdnService } from '@/common/cdn/cdn.service';

@Injectable({
    providedIn: 'root',
})
export class RecurlyImplRepository extends RecurlyRepository {
    private cardSchemaUrl: { [key: string]: string } = {
        visa: CdnService.url('vg/images/card-schema/visa.svg'),
        mastercard: CdnService.url('vg/images/card-schema/mastercard.svg'),
        'american-express': CdnService.url('vg/images/card-schema/amex.svg'),
    };
    constructor(
        private readonly http: HttpClient,
        private readonly recaptchaV3Service: ReCaptchaV3Service,
        private readonly authCheckLogin: CheckLoginUseCase,
    ) {
        super();
        (<any>window).recurly?.configure(environment.recurly.publicKey);
    }
    override createCardToken(card: {
        key?: string;
        number: string;
        first_name: string;
        last_name: string;
        address1: string;
        city: string;
        state: string;
        postal_code: string;
        country: 'MX';
        month: string;
        cvv: string;
        version?: string;
    }): Observable<CardToken> {
        card.key = (<any>window).recurly.config.publicKey;
        card.version = (<any>window).recurly.config.parentVersion;
        return this.http
            .post((<any>window).recurly.config.api + '/token', card)
            .pipe(
                switchMap((responseRecurly) => {
                    // eslint-disable-next-line no-prototype-builtins
                    if (responseRecurly?.hasOwnProperty('error')) {
                        return throwError(() => responseRecurly);
                    }
                    return of(responseRecurly);
                }),
            );
    }
    override getCardSchema(cardBin: { bin: string }): Observable<CardSchema> {
        const cardSchema: CardSchema = { bin: cardBin.bin, scheme: '' };
        const cardTypes = creditCardType(cardBin.bin);
        if (cardTypes.length === 1) {
            cardSchema.scheme = cardTypes[0].type as CreditCardTypeCardBrandId;
            cardSchema.schemeUrl = this.cardSchemaUrl[cardSchema.scheme] || '';
        }
        return of(cardSchema);
    }

    override createPurchaseCustom(
        purchaseCustomModel: PurchaseCustomModel,
    ): Observable<unknown> {
        return this.recaptchaV3Service.execute('purchase').pipe(
            switchMap((token) => {
                return forkJoin([
                    of(token),
                    this.authCheckLogin.execute().pipe(first()),
                ]);
            }),
            switchMap(([token, auth]) => {
                return this.http.post(
                    environment.vgApi.BASE_HOST +
                        environment.vgApi.ENDPOINTS.createPurchaseCustom,
                    purchaseCustomModel,
                    {
                        headers: {
                            Authorization: 'Bearer ' + auth!.accessToken,
                            'X-Channel': 'web',
                            'x-token-recaptcha': token,
                        },
                    },
                );
            }),
        );
    }
}
