import { Mapper } from '@/core/base/mapper';
import { AuthUserData } from '../store/models/auth.store';
import {
    getTimeStampPlusExpirationBySeconds,
    isTimestampOnTime,
} from '@/common/utils/strings/time.utils';
import { UserInfoSuccessResponse } from '../services/sso/responses/get-user-info.response';

interface SingleSingOnData {
    accessToken: string;
    refreshToken: string;
    expiresIn: string;
    details: UserInfoSuccessResponse;
}

export class SingleSignOnLoginMapper
    implements Mapper<SingleSingOnData, AuthUserData>
{
    mapFrom(param: SingleSingOnData): AuthUserData {
        const expiration = getTimeStampPlusExpirationBySeconds(param.expiresIn);
        return {
            accessToken: param.accessToken,
            authToken: '',
            refreshToken: param.refreshToken,
            expiration,
            isLogged: isTimestampOnTime(expiration),
            details: {
                customerNumber: param.details.sub,
                email: param.details.email,
                firstName: param.details.first,
                lastName: param.details.last,
                gender: param.details.customer_programs.personalDetails.gender,
            },
        };
    }

    /**
     * @deprecated
     */
    mapTo(param: AuthUserData): SingleSingOnData {
        throw new Error('Method not implemented.');
    }
}
