<div class="form-check">
    <input
        class="form-check-input"
        type="checkbox"
        [id]="for"
        [(ngModel)]="value"
    />
    <label class="form-check-label" [for]="for">
        <ng-content></ng-content>
    </label>
</div>
