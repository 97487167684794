import { Mapper } from '@/core/base/mapper';
import { AuthLoginInput } from '@/core/domain/auth/models/auth-login-input.data';
import { AuthData } from '@/core/domain/auth/models/auth.data';

export class AuthMapper implements Mapper<AuthData, AuthLoginInput> {
    mapFrom(param: AuthData): AuthLoginInput {
        return {
            activationCode: param.activation_code,
            sub: param.sub,
            providerType: param.provider_type,
        };
    }

    /**
     * @deprecated
     */
    mapTo(param: AuthLoginInput): AuthData {
        throw new Error('Method not implemented.');
    }
}
