import { PurchasePersistenceModel } from '@/core/domain/purchase-persistence/models/PurchasePersistence.model';
import { GetPurchaseUseCase } from '@/core/domain/purchase-persistence/usecases/get-purchase-persistence.usecase';
import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'vg-card-giftcard-summary',
    templateUrl: './vg-card-giftcard-summary.component.html',
    styleUrls: ['./vg-card-giftcard-summary.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VgCardGiftcardSummaryComponent {
    public purchasePersistence: PurchasePersistenceModel | null = null;
    constructor(private readonly getPurchasePersistence: GetPurchaseUseCase) {
        this.initPurchasePersistence();
    }
    public initPurchasePersistence(): void {
        this.getPurchasePersistence
            .execute()
            .subscribe((purchasePersistence) => {
                this.purchasePersistence = purchasePersistence;
            });
    }
}
