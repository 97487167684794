import { TranslationsRepository } from '../../core/domain/translation/repositories/translations.repository';
import { TranslationsLoaderUseCase } from '../../core/domain/translation/usecases/translations-loader.usecase';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslationsImplRepository } from './translations-impl.repository';
import { TranslationsGetLanguageUseCase } from '../../core/domain/translation/usecases/translations-get-language.usecase';
import { TranslationsTranslateRoutesUseCase } from '@/core/domain/translation/usecases/translations-translate-routes.usecase';

const translationsLoaderUseCaseFactory = (
    translationsRepository: TranslationsRepository,
) => new TranslationsLoaderUseCase(translationsRepository);

const translationsGetLanguageCaseFactory = (
    translationsRepository: TranslationsRepository,
) => new TranslationsGetLanguageUseCase(translationsRepository);

const translationsTranslateRoutesCaseFactory = (
    translationsRepository: TranslationsRepository,
) => new TranslationsTranslateRoutesUseCase(translationsRepository);

@NgModule({
    imports: [CommonModule],
    providers: [
        {
            provide: TranslationsLoaderUseCase,
            useFactory: translationsLoaderUseCaseFactory,
            deps: [TranslationsRepository],
        },
        {
            provide: TranslationsGetLanguageUseCase,
            useFactory: translationsGetLanguageCaseFactory,
            deps: [TranslationsRepository],
        },
        {
            provide: TranslationsTranslateRoutesUseCase,
            useFactory: translationsTranslateRoutesCaseFactory,
            deps: [TranslationsRepository],
        },
        {
            provide: TranslationsRepository,
            useClass: TranslationsImplRepository,
        },
    ],
})
export class TranslationsDataModule {}
