import { getLanguage } from '@/common/utils/strings/path.utils';
import { environment as env } from '@/env/environment';

export const getRedirectUri = (path?: string) => {
    const PROTOCOL = window.location.protocol;
    const HOST = window.location.host;
    let pathname = window.location.pathname;

    if (path) {
        const language = getLanguage();
        const product = env.productRootPath;
        pathname = ['', language, product, path].join('/');
    }

    return `${PROTOCOL}//${HOST}${pathname}`;
};
