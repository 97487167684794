import { CardRedeemComponent } from './card-redeem/card-redeem.component';
import { CardStepsComponent } from './card-steps/card-steps.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiModule } from '../ui/ui.module';
import { ModalErrorComponent } from './modal-error/modal-error.component';
import { ModalSuccessComponent } from './modal-success/modal-success.component';
import { CardFaqComponent } from './card-faq/card-faq.component';
import {
    NgbAccordionModule,
    NgbPaginationModule,
} from '@ng-bootstrap/ng-bootstrap';
import { CardGiftcardStatusComponent } from './card-giftcard-status/card-giftcard-status.component';
import { CardDetailsComponent } from './card-details/card-details.component';
import { CardContactComponent } from './card-contact/card-contact.component';
import { HeaderComponent } from './header/header.component';
import { DashboardTabsComponent } from './dashboard-tabs/dashboard-tabs.component';
import { VgSwiperRoutesComponent } from './vg-swiper-routes/vg-swiper-routes.component';
import { SwiperAttractionsComponent } from './swiper-attractions/swiper-attractions.component';
import { VgCardGiftcardDesignComponent } from './vg-card-giftcard-design/vg-card-giftcard-design.component';
import { VgSwiperGiftcardDesignComponent } from './vg-swiper-giftcard-design/vg-swiper-giftcard-design.component';
import { CardAmountComponent } from './card-amount/card-amount.component';
import { VgCardGiftcardSummaryComponent } from './vg-card-giftcard-summary/vg-card-giftcard-summary.component';
import { VgCardMailingComponent } from './vg-card-mailing/vg-card-mailing.component';
import { VgCardPersonalInformationComponent } from './vg-card-personal-information/vg-card-personal-information.component';
import { FooterComponent } from './footer/footer.component';
import { VgCardPaymentComponent } from './vg-card-payment/vg-card-payment.component';
import { VgCardTermsConditionsComponent } from './vg-card-terms-conditions/vg-card-terms-conditions.component';
import { CardMessageComponent } from './card-message/card-message.component';
import { SwiperLandingHomeComponent } from './swiper-landing-home/swiper-landing-home.component';
import { CardSendComponent } from './card-send/card-send.component';
import { CardCheckGiftComponent } from './card-check-gift/card-check-gift.component';
import { PurchaseConfirmationComponent } from './purchase-confirmation/purchase-confirmation.component';
import { CardGiftcardReadyComponent } from './card-giftcard-ready/card-giftcard-ready.component';
import { VgCardGiftcardComponent } from './vg-card-gifcard/vg-card-giftcard.component';
import { VgCardGiftcardCodeComponent } from './vg-card-giftcard-code/vg-card-giftcard-code.component';
import { OrderStatusComponent } from './order-status/order-status.component';
import { RecurlyDataModule } from '../../../data/recurly/recurly-data.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../../../common/pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { DirectivesModule } from '../../../common/directives/directives.module';
import { NgxMaskPipe } from 'ngx-mask';
import { ModalSingleSignOnErrorComponent } from './modal-sso-error/modal-sso-error.component';
import { ModalThreedsComponent } from './modal-threeds/modal-threeds.component';
import { ModalLoaderComponent } from './modal-loader/modal-loader.component';
import { LottieModule } from 'ngx-lottie';
import { TermsDonditionsComponent } from './terms-conditions/terms-conditions.component';

export function playerFactory() {
    return import('lottie-web');
}

@NgModule({
    declarations: [
        CardAmountComponent,
        CardCheckGiftComponent,
        CardContactComponent,
        CardDetailsComponent,
        CardFaqComponent,
        CardGiftcardReadyComponent,
        CardGiftcardStatusComponent,
        CardMessageComponent,
        CardRedeemComponent,
        CardSendComponent,
        CardStepsComponent,
        DashboardTabsComponent,
        FooterComponent,
        HeaderComponent,
        MaintenanceComponent,
        ModalErrorComponent,
        ModalSingleSignOnErrorComponent,
        ModalSuccessComponent,
        OrderStatusComponent,
        PurchaseConfirmationComponent,
        SwiperAttractionsComponent,
        SwiperLandingHomeComponent,
        VgCardGiftcardCodeComponent,
        VgCardGiftcardComponent,
        VgCardGiftcardDesignComponent,
        VgCardGiftcardSummaryComponent,
        VgCardMailingComponent,
        VgCardPaymentComponent,
        VgCardPersonalInformationComponent,
        VgCardTermsConditionsComponent,
        VgSwiperGiftcardDesignComponent,
        VgSwiperRoutesComponent,
        ModalThreedsComponent,
        ModalLoaderComponent,
        TermsDonditionsComponent,
    ],
    exports: [
        CardAmountComponent,
        CardCheckGiftComponent,
        CardContactComponent,
        CardDetailsComponent,
        CardFaqComponent,
        CardGiftcardReadyComponent,
        CardGiftcardStatusComponent,
        CardMessageComponent,
        CardRedeemComponent,
        CardSendComponent,
        CardStepsComponent,
        DashboardTabsComponent,
        FooterComponent,
        HeaderComponent,
        MaintenanceComponent,
        ModalErrorComponent,
        ModalSingleSignOnErrorComponent,
        ModalSuccessComponent,
        OrderStatusComponent,
        PurchaseConfirmationComponent,
        SwiperAttractionsComponent,
        SwiperLandingHomeComponent,
        VgCardGiftcardCodeComponent,
        VgCardGiftcardComponent,
        VgCardGiftcardDesignComponent,
        VgCardGiftcardSummaryComponent,
        VgCardMailingComponent,
        VgCardPaymentComponent,
        VgCardPersonalInformationComponent,
        VgCardTermsConditionsComponent,
        VgSwiperGiftcardDesignComponent,
        VgSwiperRoutesComponent,
        ModalThreedsComponent,
        ModalLoaderComponent,
        TermsDonditionsComponent,
    ],
    imports: [
        UiModule,
        CommonModule,
        NgbAccordionModule,
        NgbPaginationModule,
        RecurlyDataModule,
        ReactiveFormsModule,
        FormsModule,
        NgSelectModule,
        TranslateModule.forChild({
            extend: true,
        }),
        PipesModule,
        RouterModule,
        DirectivesModule,
        NgxMaskPipe,
        LottieModule.forRoot({ player: playerFactory }),
    ],
})
export class ComponentsModule {}
