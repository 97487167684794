import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replace',
})
export class ReplacePipe implements PipeTransform {
    transform(value: string, toReplace: any): unknown {
        return value.replace(/{([^{}]+)}/g, (keyExpr, key) => {
            return toReplace[key] || '';
        });
    }
}
