import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'vg-card-contact',
    templateUrl: './card-contact.component.html',
    styleUrls: ['./card-contact.component.scss'],
})
export class CardContactComponent {
    @Output() newItemEvent = new EventEmitter();
    onClickMail() {
        this.newItemEvent.emit();
    }
}
