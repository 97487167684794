<vg-card>
    <div class="container container-terms-conditions">
        <div class="row align-items-center">
            <div
                [innerHTML]="
                    'vg_purchase_payment_card_div_message-accept-terms-conditions'
                        | translate
                "
                class="col-6 col-lg-7 terms-conditions font-family-lato"
            ></div>
            <div class="col-6 col-lg-5">
                <vg-button
                    [loading]="loading"
                    [style]="'tertiary'"
                    class="d-grid"
                    [disabled]="!enabledFinish"
                    (click)="executeImportantAction()"
                    >{{
                        'vg_purchase_payment_vg_button_finish-purchase'
                            | translate
                    }}</vg-button
                >
            </div>
        </div>
    </div>
    <div class="container container-terms-conditions-mobile">
        <div class="row align-items-center">
            <div class="col-12">
                <vg-button
                    [loading]="loading"
                    [style]="'tertiary'"
                    class="d-grid"
                    [disabled]="!enabledFinish"
                    (click)="executeImportantAction()"
                    >{{
                        'vg_purchase_payment_vg_button_finish-purchase'
                            | translate
                    }}</vg-button
                >
            </div>
            <div
                [innerHTML]="
                    'vg_purchase_payment_card_div_message-accept-terms-conditions'
                        | translate
                "
                class="col-12 terms-conditions font-family-lato text-center text-md-start"
            ></div>
        </div>
    </div>
</vg-card>
