import { UseCase } from '@/core/base/use-case';
import { TranslationsRepository } from '../repositories/translations.repository';
import { Observable } from 'rxjs';
import { LanguageModel } from '../models/language.model';

export type LoaderTranslationsUseCaseInput = {
    lang: 'es-mx' | 'en-us';
};

export class TranslationsGetLanguageUseCase
    implements UseCase<never, LanguageModel>
{
    constructor(private readonly repository: TranslationsRepository) {}

    execute(): Observable<LanguageModel> {
        return this.repository.getLanguague();
    }
}
