import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatNumber',
})
export class FormatNumberPipe implements PipeTransform {
    transform(
        value: number | string | null | undefined,
        prefix = '$',
    ): unknown {
        return `${prefix}${formatNumber(value as number, 'en-IN', '1.0-2')}`;
    }
}
