<h1 *ngIf="isLoading || cards.length > 0" class="os-title fw-bold">
    {{ title }}
</h1>
<span
    *ngIf="isLoading || cards.length > 0"
    class="os-subtitle font-family-lato"
    >{{ subTitle }}</span
>
<div class="card shadow-card my-3" aria-hidden="true" *ngIf="isLoading">
    <div class="card-body">
        <p
            class="card-text placeholder-glow position-absolute d-none d-lg-flex"
        >
            <span class="placeholder col-12"></span>
        </p>
        <p class="card-text placeholder-glow flex-column d-flex mb-4">
            <span class="placeholder col-5 col-lg-3"></span>
            <span class="placeholder col-3 col-lg-2 mt-2"></span>
        </p>
        <p
            class="card-text placeholder-glow d-flex justify-content-between mb-1"
        >
            <span class="placeholder col-5 col-lg-2"></span>
            <span class="placeholder col-5 col-lg-2"></span>
            <span class="placeholder col-lg-2 d-none d-lg-flex"></span>
            <span class="col-2 d-none d-lg-flex" style="opacity: 0"></span>
        </p>
        <p class="card-text placeholder-glow d-flex justify-content-between">
            <span class="placeholder col-5 col-lg-2"></span>
            <span class="placeholder col-5 col-lg-2"></span>
            <span class="placeholder col-5 col-lg-2 d-none d-lg-flex"></span>
            <span class="placeholder col-lg-2 d-none d-lg-flex"></span>
        </p>
    </div>
</div>

<div class="card shadow-card my-3" aria-hidden="true" *ngIf="isLoading">
    <div class="card-body">
        <p
            class="card-text placeholder-glow position-absolute d-none d-lg-flex"
        >
            <span class="placeholder col-12"></span>
        </p>
        <p class="card-text placeholder-glow flex-column d-flex mb-4">
            <span class="placeholder col-5 col-lg-3"></span>
            <span class="placeholder col-3 col-lg-2 mt-2"></span>
        </p>
        <p
            class="card-text placeholder-glow d-flex justify-content-between mb-1"
        >
            <span class="placeholder col-5 col-lg-2"></span>
            <span class="placeholder col-5 col-lg-2"></span>
            <span class="placeholder col-lg-2 d-none d-lg-flex"></span>
            <span class="col-2 d-none d-lg-flex" style="opacity: 0"></span>
        </p>
        <p class="card-text placeholder-glow d-flex justify-content-between">
            <span class="placeholder col-5 col-lg-2"></span>
            <span class="placeholder col-5 col-lg-2"></span>
            <span class="placeholder col-5 col-lg-2 d-none d-lg-flex"></span>
            <span class="placeholder col-lg-2 d-none d-lg-flex"></span>
        </p>
    </div>
</div>

<vg-card-giftcard-status
    [cardsReceived]="cards"
    *ngIf="!isLoading"
    [urlDetail]="urlDetail"
    [typeDetail]="typeDetail"
></vg-card-giftcard-status>
