import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'vg-modal-success',
    templateUrl: './modal-success.component.html',
    styleUrls: ['./modal-success.component.scss'],
})
export class ModalSuccessComponent {
    @Output() closeEventSuccess = new EventEmitter<boolean>();
    @Input()
    title = '';
    @Input()
    subtitle = '';
    @Input()
    expirationDateFormatted = '';
    @Input()
    btnTitle = '';
    @Input()
    text = '';
    @Input()
    isCompensation = false;

    sendClose() {
        this.closeEventSuccess.emit(false);
    }

    public sendSuccess(): void {
        this.closeEventSuccess.emit(true);
    }
}
