import { GlobalState } from '@/core/domain/persistence/models/global-state.model';
import { ActionReducer } from '@ngrx/store';
import { DateTime } from 'luxon';
import { resetToken, updateToken } from '../auth/store/auth.actions';

export const LOCAL_STORAGE_VIVA_USER_TOKEN_EXPIRES_STATE_KEY =
    'viva-user-token-expires';

export const LOCAL_STORAGE_VIVA_USER_TOKEN_STATE_KEY = 'viva-user-token';

export const vivaUserTokenExpirationMetaReducer = (
    reducer: ActionReducer<GlobalState>,
): ActionReducer<GlobalState> => {
    return (state, action) => {
        const nextState = reducer(state, action);

        if (action.type === updateToken.type) {
            const { expiration } = nextState.auth;
            const isoExpiration = DateTime.fromMillis(expiration)
                .toUTC()
                .toISO();

            if (isoExpiration) {
                localStorage.setItem(
                    LOCAL_STORAGE_VIVA_USER_TOKEN_EXPIRES_STATE_KEY,
                    isoExpiration,
                );
            }
        } else if (action.type === resetToken.type) {
            localStorage.removeItem(
                LOCAL_STORAGE_VIVA_USER_TOKEN_EXPIRES_STATE_KEY,
            );
        }

        return nextState;
    };
};
