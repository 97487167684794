import {
    Component,
    EventEmitter,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { auto } from '@popperjs/core';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

@Component({
    selector: 'vg-swiper-message',
    templateUrl: './swiper-message.component.html',
    styleUrls: ['./swiper-message.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SwiperMessageComponent implements OnInit {
    public count = 0;
    isMobile = false;
    @Output() newItemEvent = new EventEmitter<string>();

    constructor(private translate: TranslateService) {}

    ngOnInit(): void {
        const swiper = new Swiper('.swiper-message', {
            modules: [Navigation],
            direction: 'horizontal',
            loop: true,
            slidesPerView: 'auto',
            spaceBetween: '10px',
            navigation: {
                nextEl: '.swiper-message-next',
                prevEl: '.swiper-message-next',
            },
            allowTouchMove: false,
        });
        this.getScreenSize();
        this.sliderUpdate();
        swiper.on('slideChangeTransitionStart', () => {
            this.sliderUpdate();
        });

        swiper.on('slideChange', () => {
            this.sliderUpdate();
        });
    }

    public onClickMessage(key: string): void {
        this.newItemEvent.emit(this.translate.instant(key));
    }

    public sliderUpdate() {
        const slider = document.querySelectorAll(
            '.swiper-message  .swiper-slide',
        );
        let active: number;
        let next: number;
        if (slider) {
            slider.forEach((slider) => {
                const htmlElement = slider as HTMLElement;

                if (htmlElement.classList.contains('swiper-slide-prev')) {
                    this.adjustPrevSlide(htmlElement);
                } else if (
                    htmlElement.classList.contains('swiper-slide-active')
                ) {
                    active = this.adjustActiveSlide(htmlElement);
                } else if (
                    htmlElement.classList.contains('swiper-slide-next')
                ) {
                    next = this.adjustNextSlide(htmlElement);
                } else {
                    this.adjustOtherSlide(htmlElement, active, next);
                }
            });
        }
    }
    adjustPrevSlide(htmlElement: HTMLElement) {
        if (this.count == 0 && !this.isMobile) {
            htmlElement.style.width = '7.3125rem';
            this.count++;
        } else {
            htmlElement.style.width = 'auto';
        }
    }

    adjustActiveSlide(htmlElement: HTMLElement): number {
        htmlElement.style.width = 'auto';
        if (this.isMobile) {
            htmlElement.style.visibility = 'visible';
        }
        return htmlElement.clientWidth;
    }

    adjustNextSlide(htmlElement: HTMLElement): number {
        htmlElement.style.width = 'auto';
        if (this.isMobile) {
            htmlElement.style.visibility = 'visible';
        }
        return htmlElement.clientWidth;
    }

    adjustOtherSlide(htmlElement: HTMLElement, active: number, next: number) {
        if (this.isMobile) {
            htmlElement.style.visibility = 'hidden';
        } else {
            htmlElement.style.width = `${317 - active - next - 32}px`;
        }
    }
    getScreenSize() {
        this.isMobile = window.innerWidth <= 767;
    }
}
