<div class="card-contact mx-auto">
    <h5 class="fs-5 fw-bold font-family-axiforma text-center">
        {{ 'vg_landing_card_contact_h5-title' | translate }}
    </h5>
    <p class="font-family-lato text-center m-0">
        {{ 'vg_landing_card_contact_information' | translate }}
        <a
            class="text-decoration-none"
            href="mailto:vivagift@vivaaerobus.com"
            (click)="onClickMail()"
        >
            {{ 'vg_landing_card_contact_information-mail' | translate }}
        </a>
    </p>
</div>
