import { UseCase } from '@/core/base/use-case';
import { Observable } from 'rxjs';
import { OrdersModelKeys } from '../models/orders.model';
import { OrdersRepository } from '../repositories/orders.repository';

export class GetCardPDFParamsUseCase<T> implements UseCase<string, T> {
    constructor(private readonly repository: OrdersRepository) {}

    execute(param: string): Observable<T> {
        return this.repository.getCardPDF<T>(param);
    }
}
