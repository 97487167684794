<vg-modal-wrapper>
    <div
        class="modal fade"
        id=""
        tabindex="-1"
        aria-modal="true"
        role="dialog"
        style="display: block"
        #Modal
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header position-relative border-0">
                    <div class="modal-text-container">
                        <p class="fs-5 mb-0 fw-bold font-family-axiforma">
                            {{ 'vg_sso_vg_modal_wrapper_p_title' | translate }}
                        </p>
                    </div>
                    <button
                        type="button"
                        class="position-absolute rounded-circle border-0 d-flex justify-content-center"
                        data-bs-dismiss="modal"
                        (click)="setCloseModal()"
                    >
                        <img [src]="'vg/images/close-black.svg' | cdn" alt="" />
                    </button>
                </div>
                <div
                    class="modal-body d-flex justify-content-center flex-column pt-0"
                >
                    <p class="lh-1 font-family-lato">
                        {{
                            'vg_sso_vg_modal_wrapper_p_descripcion' | translate
                        }}
                    </p>

                    <p
                        [innerHTML]="
                            'vg_sso_vg_modal_wrapper_p_contact' | translate
                        "
                        class="lh-1 font-family-lato contact-description"
                    ></p>
                    <vg-button
                        *ngIf="!redirectSSO"
                        [style]="'success-outline'"
                        class="w-100 d-grid"
                        (click)="setCloseModal()"
                    >
                        {{
                            'vg_sso_vg_modal_wrapper_vg_button_close'
                                | translate
                        }}
                    </vg-button>
                    <vg-button
                        *ngIf="redirectSSO"
                        [style]="'success-outline'"
                        class="w-100 d-grid"
                        (click)="login()"
                    >
                        {{ 'vg_landing_purchase_custom_btn_login' | translate }}
                    </vg-button>
                </div>
            </div>
        </div>
    </div>
</vg-modal-wrapper>
