<div class="card-info-mailing" [@fadeInOut]>
    <vg-card>
        <div class="container container-info-mailing px-0">
            <div class="row info-mailing-title info-mailing-value">
                <div class="col-12 col-md-6 col-lg-3 receiver">
                    <dl class="mb-0">
                        <dt class="title">
                            {{
                                'vg_landing_purchase_payment_personal_info-receiver-label'
                                    | translate
                            }}
                        </dt>
                        <dd class="font-family-lato mb-0">
                            {{
                                auth?.details?.firstName +
                                    ' ' +
                                    auth?.details?.lastName
                            }}
                        </dd>
                    </dl>
                </div>
                <div class="col-12 col-md-6 col-lg-8">
                    <dl class="mb-0">
                        <dt class="title">
                            {{
                                'vg_landing_purchase_payment_personal_info-mail-label'
                                    | translate
                            }}
                        </dt>
                        <dd class="font-family-lato mb-0">
                            {{ auth?.details?.email }}
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    </vg-card>
</div>
