import { UseCase } from '@/core/base/use-case';
import { Observable } from 'rxjs';
import { RecurlyRepository } from '../repositories/recurly.repository';
import { PurchaseCustomModel } from '../models/PurchaseCustomModel';

export class CreatePurchaseCustomUseCase
    implements UseCase<PurchaseCustomModel, unknown>
{
    constructor(private readonly repository: RecurlyRepository) {}

    execute(params: PurchaseCustomModel): Observable<unknown> {
        return this.repository.createPurchaseCustom(params);
    }
}
