import { PurchasePersistenceModel } from '@/core/domain/purchase-persistence/models/PurchasePersistence.model';
import { GetPurchaseUseCase } from '@/core/domain/purchase-persistence/usecases/get-purchase-persistence.usecase';
import { TranslationsGetLanguageUseCase } from '@/core/domain/translation/usecases/translations-get-language.usecase';
import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'vg-card-mailing',
    templateUrl: './vg-card-mailing.component.html',
    styleUrls: ['./vg-card-mailing.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VgCardMailingComponent {
    public purchasePersistence: PurchasePersistenceModel | null = null;

    public lang = 'es-mx';

    constructor(
        private readonly getPurchasePersistence: GetPurchaseUseCase,
        private readonly getLanguage: TranslationsGetLanguageUseCase,
    ) {
        this.initPurchasePersistence();
        this.initLanguage();
    }

    public initLanguage(): void {
        this.getLanguage.execute().subscribe((lang) => {
            this.lang = lang.language;
        });
    }

    public initPurchasePersistence(): void {
        this.getPurchasePersistence
            .execute()
            .subscribe((purchasePersistence) => {
                this.purchasePersistence = purchasePersistence;
            });
    }
}
