import { AuthModuleConfig } from '@/core/domain/auth/models/auth-module.config';
import { AuthApi } from '@/core/domain/auth/services/auth.api';
import { SingleSignOnApi } from '@/core/domain/auth/services/sso.api';
import { InjectionToken } from '@angular/core';

import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthImplRepository } from './repositories/auth-impl.repository';
import { SingleSignOnImplRepository } from './repositories/sso-impl.repository';

export const FOR_ROOT_AUTH_OPTIONS_TOKEN = new InjectionToken<AuthModuleConfig>(
    'forRoot(): AUTH DATA MODULE CONFIGURATIONS',
);

export const ImplementationRepositoryFactory = (
    store: Store,
    auth: AuthApi,
    sso: SingleSignOnApi,
    configs: AuthModuleConfig,
    router: Router,
) => {
    const mechanism = configs.mechanism;

    if (mechanism === 'ezy') {
        return new AuthImplRepository(store, auth, sso, router);
    }

    if (mechanism === 'sso') {
        return new SingleSignOnImplRepository(store, sso, auth);
    }

    throw new Error('No selected mechanism');
};
