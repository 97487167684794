import { UseCase } from '@/core/base/use-case';
import { Observable } from 'rxjs';
import { PurchasePersistenceRepository } from '../repositories/purchase-persistence.repository';

export class DeletePurchaseUseCase implements UseCase<void, void> {
    constructor(private readonly repository: PurchasePersistenceRepository) {}

    execute(): Observable<void> {
        return this.repository.deletePurchase();
    }
}
