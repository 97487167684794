<h2 class="title-h2">
    {{ 'vg_landing_purchase_send_h2_title' | translate }}
</h2>
<div class="container-card-send" [@fadeInOut]>
    <vg-card>
        <div class="col-12">
            <form [formGroup]="sendForm">
                <vg-input
                    label="{{
                        'vg_landing_purchase_send_input-name-label' | translate
                    }}"
                    placeholder="{{
                        'vg_landing_purchase_send_input-name-placeholder'
                            | translate
                    }}"
                    formControlName="recipientName"
                    [flowPage]="'purchase_send'"
                    [autocomplete]="'off'"
                    [maxlength]="'20'"
                ></vg-input>
                <vg-input
                    label="{{
                        'vg_landing_purchase_send_input-email-label' | translate
                    }}"
                    placeholder="{{
                        'vg_landing_purchase_send_input-email-placeholder'
                            | translate
                    }}"
                    formControlName="recipientEmail"
                    [flowPage]="'purchase_send'"
                    [type]="'email'"
                    [autocomplete]="'off'"
                ></vg-input>
                <vg-check
                    class="text-pe"
                    [for]="'scheduleShipping'"
                    formControlName="scheduleShipping"
                >
                    {{ 'vg_landing_purchase_send_check-text' | translate }}
                </vg-check>
                <vg-input
                    *ngIf="sendForm.get('scheduleShipping')?.value"
                    [@fadeInOut]
                    [(ngModel)]="currentDate"
                    [icon]="'calendar'"
                    [iconInactive]="'calendar-inactive'"
                    [isDatepicker]="true"
                    [label]="
                        'vg_landing_purchase_send_input-calendar-label'
                            | translate
                    "
                    [min]="minDate"
                    [max]="maxDate"
                    [placeholder]="
                        'vg_landing_purchase_send_input-calendar-placeholder'
                            | translate
                    "
                    formControlName="recipientScheduleShipping"
                    [flowPage]="'purchase_send'"
                ></vg-input>
                <small class="text-small mb-3">
                    {{ 'vg_landing_purchase_send_text-small' | translate }}
                </small>
                <p class="text-desc">
                    {{ 'vg_landing_purchase_send_text-desc' | translate }}
                </p>
                <small class="text-small mb-30">
                    {{ 'vg_landing_purchase_send_text-desc-small' | translate }}
                </small>
            </form>
            <div>
                <vg-button
                    [id]="'btn-confirm-send'"
                    [style]="'success-outline'"
                    [disabled]="!this.sendForm.valid || !enabledBtnNext"
                    (click)="onSubmit()"
                    class="w-100 d-grid"
                >
                    {{ 'vg_landing_purchase_send_btn_next' | translate }}
                </vg-button>
            </div>
        </div>
    </vg-card>
</div>
