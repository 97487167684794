import { UseCase } from '@/core/base/use-case';
import { Observable } from 'rxjs';
import { ConfigurationsModelKeys } from '../models/configurations.model';
import { ConfigurationsRepository } from '../repositories/configurarions.repository';

export type LoaderAmountsUseCaseInput = ConfigurationsModelKeys;

export class GetConfigParamsUseCase<T>
    implements UseCase<LoaderAmountsUseCaseInput, T>
{
    constructor(private readonly repository: ConfigurationsRepository) {}

    execute(param: LoaderAmountsUseCaseInput): Observable<T> {
        return this.repository.getConfig<T>(param);
    }
}
