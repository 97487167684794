import { Component, Input } from '@angular/core';

@Component({
    selector: 'vg-modal-loader',
    templateUrl: './modal-loader.component.html',
    styleUrls: ['./modal-loader.component.scss'],
})
export class ModalLoaderComponent {
    @Input() show = false;
}
