<vg-modal-error
    *ngIf="modalError"
    (closeEvent)="receiveClose($event)"
    [btnTitle]="errorRedeemValues.btnTitle"
    [text]="errorRedeemValues.text"
    [title]="errorRedeemValues.title"
    [show]="modalError"
></vg-modal-error>

<vg-modal-success
    *ngIf="modalSuccess"
    [expirationDateFormatted]="expirationDateFormattedSuccess"
    [isCompensation]="isCompensation"
    (closeEventSuccess)="receiveCloseSuccess($event)"
></vg-modal-success>

<div class="card-redeem-container">
    <div class="row mx-0">
        <div class="col-12 col-md-6 px-0">
            <div class="w-100">
                <div>
                    <h2 class="text-h2 text-center text-md-start mb-0">
                        {{ title }}
                    </h2>
                    <p class="text-p text-center text-md-start mb-40">
                        {{ subtitle }}
                    </p>
                </div>
                <div class="d-md-none mb-60 img-redeem text-center">
                    <img
                        [src]="image"
                        class="card-redeem-img"
                        alt="alternative text"
                    />
                </div>
                <form [formGroup]="cardRedeemForm">
                    <div
                        class="mb-20 m-auto m-md-0 {{
                            btnCompensations ? 'p-input' : ''
                        }}"
                    >
                        <vg-input
                            [placeholder]="
                                'vg_component_card_redeem_input-placeholder'
                                    | translate
                            "
                            [label]="
                                'vg_component_card_redeem_input-code'
                                    | translate
                            "
                            [mask]="
                                'vg_component_card_redeem_input-mask'
                                    | translate
                            "
                            formControlName="cardNumber"
                            [flowPage]="'card_redeem'"
                            (input)="onChangeCardNumber($event)"
                        ></vg-input>
                    </div>
                </form>
                <div
                    class="m-auto m-md-0 {{
                        btnCompensations ? 'p-buttom' : ''
                    }}"
                >
                    <vg-button
                        [style]="
                            enabledBtnRedeem ? 'success-outline' : 'link-light'
                        "
                        [loading]="isLoadingBtn"
                        [disabled]="!enabledBtnRedeem"
                        class="w-100 d-grid"
                        (click)="onClickRedeem()"
                        >{{ btnTitle }}</vg-button
                    >
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 d-none d-md-flex justify-content-center">
            <img [src]="image" class="card-redeem-img" alt="alternative text" />
        </div>
    </div>
</div>
