import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class CdnService {
    public static url(resource: string): string {
        return [environment.cdn, resource.replace(/^\/+/, '')].join('/');
    }
}
