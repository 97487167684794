import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickOutsideDirective } from './click-outside/click-outside.directive';
import { OnClickSendTagDirective } from './on-click-send-tag/on-click-send-tag.directive';
import { EnterTheViewportNotifierDirective } from './enter-viewport-notifier/enter-the-viewport-notifier.directive';

@NgModule({
    declarations: [
        ClickOutsideDirective,
        OnClickSendTagDirective,
        EnterTheViewportNotifierDirective,
    ],
    exports: [
        ClickOutsideDirective,
        OnClickSendTagDirective,
        EnterTheViewportNotifierDirective,
    ],
    imports: [CommonModule],
})
export class DirectivesModule {}
