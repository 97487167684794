import { createReducer, on } from '@ngrx/store';
import { loadAction } from './translation.actions';
import { TranslationModel } from '@/core/domain/translation/models/translation.model';
import { loadReducer } from './translation.reducer';

export const TRANSLATION_INITIAL_STATE: TranslationModel = {};

export const TranslationReducer = createReducer(
    TRANSLATION_INITIAL_STATE,
    on(loadAction, loadReducer),
);
