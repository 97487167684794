import { Component, Input } from '@angular/core';

@Component({
    selector: 'vg-modal-wrapper',
    templateUrl: './modal-wrapper.component.html',
    styleUrls: ['./modal-wrapper.component.scss'],
})
export class ModalWrapperComponent {
    @Input() show = true;
}
