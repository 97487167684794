import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'vg-purchase-confirmation',
    templateUrl: './purchase-confirmation.component.html',
    styleUrls: ['./purchase-confirmation.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PurchaseConfirmationComponent {
    @Input() card: any = {};

    constructor(private translate: TranslateService) {}

    public status: any = {
        received: {
            text: this.translate.instant('vg-card-giftcard-status-received'),
            class: 'success',
        },
        redeemed: {
            text: this.translate.instant('vg-card-giftcard-status-redeemed'),
            class: 'info',
        },
        expired: {
            text: this.translate.instant('vg-card-giftcard-status-expired'),
            class: 'disabled',
        },
        review: {
            text: this.translate.instant('vg-card-giftcard-status-review'),
            class: 'warning',
        },
        canceled: {
            text: this.translate.instant('vg-card-giftcard-status-canceled'),
            class: 'tertiary',
        },
        created: {
            text: this.translate.instant('vg-card-giftcard-status-created'),
            class: 'success',
        },
        purchased: {
            text: this.translate.instant('vg-card-giftcard-status-purchased'),
            class: 'success',
        },
        sent: {
            text: this.translate.instant('vg-card-giftcard-status-sent'),
            class: 'light',
        },
        scheduled: {
            text: this.translate.instant('vg-card-giftcard-status-scheduled'),
            class: 'light',
        },
        compensation: {
            text: this.translate.instant(
                'vg-card-giftcard-status-compensation',
            ),
            class: 'light',
        },
        refund: {
            text: this.translate.instant('vg-card-giftcard-status-refund'),
            class: 'light',
        },
        default: {
            text: this.translate.instant('vg-card-giftcard-status-default'),
            class: 'light',
        },
    };
}
