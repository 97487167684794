import { environment as env } from '@/env/environment';
import { getLanguage } from '../strings/path.utils';

interface PartnerRequestConfig {
    redirectUri?: string;
    state?: string;
    go_to_page?: string;
}

export const getPartnerRequest = (config?: PartnerRequestConfig) => {
    const HOST = env.SSO.PARTNER.host;

    const params = {
        clientId: env.SSO.PARTNER.clientId,
        clientSecret: env.SSO.PARTNER.clientSecret,
        language: env.SSO.PARTNER.language,
        redirectUri: config?.redirectUri ?? env.SSO.PARTNER.redirectUri,
        state: config?.state ?? env.SSO.PARTNER.state,
    };

    const queryParams = new URLSearchParams(params).toString();

    return `${HOST}?${queryParams}`;
};

const getPostLogOutUri = () => {
    const HOST = env.SSO.PARTNER.redirectUri;
    const language = getLanguage();
    const defaultPage = 'vivagift';

    return [HOST, language, defaultPage].join('/');
};

export const getLogoutUri = () => {
    const HOST = env.SSO.PARTNER.host;
    const postLogOutUri = getPostLogOutUri();

    const params = {
        post_logout_redirect_uri: postLogOutUri,
        client_id: env.SSO.PARTNER.clientId,
    };

    const queryParams = new URLSearchParams(params).toString();

    return `${HOST}/logout?${queryParams}`;
};
