<div class="contenedor-swiper-attractions my-3 border">
    <vg-swiper [type]="'banner'" [showPagination]="true">
        <div class="swiper-slide bg-primary h-auto">
            <div
                class="d-flex justify-content-center justify-content-lg-between align-items-end flex-wrap flex-lg-nowrap cont-flex"
            >
                <div class="text-center text-lg-start">
                    <h1 class="text-h1">
                        {{
                            'vg_landing_dashboard_home_swiper-attractions-title'
                                | translate
                        }}
                    </h1>
                    <p class="text-p">
                        {{
                            'vg_landing_dashboard_home_swiper-attractions-subtitle'
                                | translate
                        }}
                    </p>
                    <div class="d-lg-none mb-30">
                        <img
                            [src]="image | cdn"
                            alt="banner plane image"
                            width="225"
                        />
                    </div>
                    <div
                        class="d-flex justify-content-center justify-content-lg-start cont-flex-button"
                    >
                        <a
                            [style]="'light-outline'"
                            class="d-grid w-100 mw-300 btn btn-outline-light"
                            (click)="gtmAction('give_vivagift')"
                            [routerLink]="'purchase/design' | formatUrl | async"
                            >{{
                                'vg_landing_dashboard_home_swiper-attractions-text-button'
                                    | translate
                            }}</a
                        >
                    </div>
                </div>
                <div class="d-none d-lg-block">
                    <img [src]="image | cdn" alt="banner plane image" />
                </div>
            </div>
        </div>
        <div class="swiper-slide bg-secondary-banner h-auto">
            <div
                class="d-flex justify-content-center justify-content-lg-between align-items-end flex-wrap flex-lg-nowrap cont-flex"
            >
                <div class="text-center text-lg-start">
                    <h3 class="text-h3">
                        {{
                            'vg_landing_dashboard_home_swiper-attractions-title2'
                                | translate
                        }}
                    </h3>
                    <div class="d-lg-none mb-30">
                        <img
                            [src]="image2 | cdn"
                            alt="banner plane image"
                            width="197"
                        />
                    </div>
                    <div
                        class="d-flex justify-content-center justify-content-lg-start cont-flex-button"
                    >
                        <a
                            class="d-grid w-100 mw-300 btn btn-outline-light"
                            (click)="gtmAction('vivagift_enteprise')"
                            [routerLink]="'/' | formatUrl | async"
                            fragment="gift-bussiness"
                            >{{
                                'vg_landing_dashboard_home_swiper-attractions-text-button2'
                                    | translate
                            }}</a
                        >
                    </div>
                </div>
                <div class="d-none d-lg-block">
                    <img [src]="image2 | cdn" alt="banner plane image" />
                </div>
            </div>
        </div>
    </vg-swiper>
</div>
