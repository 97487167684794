import { UseCase } from '@/core/base/use-case';
import { Observable } from 'rxjs';
import { CardToken } from '../models/CardToken';
import { RecurlyRepository } from '../repositories/recurly.repository';

export interface CreateCardTokenRecurlyUseCaseInput {
    key?: string;
    number: string;
    first_name: string;
    last_name: string;
    address1: string;
    city: string;
    state: string;
    postal_code: string;
    country: 'MX';
    month: string;
    year: string;
    cvv: string;
    version?: string;
}

export class CreateTokenCardUseCase
    implements UseCase<CreateCardTokenRecurlyUseCaseInput, CardToken>
{
    constructor(private readonly repository: RecurlyRepository) {}

    execute(params: CreateCardTokenRecurlyUseCaseInput): Observable<CardToken> {
        return this.repository.createCardToken(params);
    }
}
