import { Mapper } from '@/core/base/mapper';
import { LoginExternalData } from '../responses/login-external.response';
import { AuthUserData } from '@/data/auth/store/models/auth.store';

export class LoginMapper implements Mapper<LoginExternalData, AuthUserData> {
    mapFrom(param: LoginExternalData): AuthUserData {
        return new AuthUserData(param);
    }

    /**
     * @deprecated
     */
    mapTo(param: AuthUserData): LoginExternalData {
        throw new Error('Method not implemented.');
    }
}
