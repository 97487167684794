<vg-modal-wrapper [show]="show">
    <div
        class="modal fade show"
        id=""
        tabindex="-1"
        aria-modal="true"
        role="dialog"
        [ngClass]="show ? 'd-block' : 'd-none'"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div
                    class="modal-header position-relative p-0 justify-content-end"
                >
                    <vg-button
                        (click)="sendClose()"
                        [onlyIcon]="true"
                        [icon]="'close-black'"
                        [style]="'link'"
                    ></vg-button>
                </div>
                <div
                    class="modal-body d-flex justify-content-center flex-column"
                >
                    <div class="container-3d-secure"></div>
                </div>
            </div>
        </div>
    </div>
</vg-modal-wrapper>
