import { NgModule } from '@angular/core';

import { ModalErrorRepository } from '@/core/domain/modal-error/repositories/modal-error.repository';
import { ModalErrorCloseUseCase } from '@/core/domain/modal-error/usecases/modal-error-close.usecase';
import { ModalErrorOpenUseCase } from '@/core/domain/modal-error/usecases/modal-error-open.usecase';
import { ModalErrorImplRepository } from './modal-error-impl.repository';

const modalErrorOpenUseCaseFactory = (repo: ModalErrorRepository) =>
    new ModalErrorOpenUseCase(repo);

const modalErrorCloseUseCaseFactory = (repo: ModalErrorRepository) =>
    new ModalErrorCloseUseCase(repo);

@NgModule({
    providers: [
        {
            provide: ModalErrorOpenUseCase,
            useFactory: modalErrorOpenUseCaseFactory,
            deps: [ModalErrorRepository],
        },
        {
            provide: ModalErrorCloseUseCase,
            useFactory: modalErrorCloseUseCaseFactory,
            deps: [ModalErrorRepository],
        },
        {
            provide: ModalErrorRepository,
            useClass: ModalErrorImplRepository,
        },
    ],
})
export class ModalErrorDataModule {}
