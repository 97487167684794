<vg-card-giftcard [img]="purchasePersistence?.cardDesign?.resource">
    <div class="container-summary">
        <div class="to">
            {{ 'vg_landing_purchase_payment_to-text' | translate }}
            {{ purchasePersistence?.shipment?.recipientName }}
        </div>
        <div class="from">
            {{ 'vg_landing_purchase_payment_from-text' | translate }}
            {{ purchasePersistence?.message?.from }}
        </div>
        <div class="message font-family-lato">
            {{ purchasePersistence?.message?.message }}
        </div>
    </div>
    <div class="container-btn">
        <vg-button
            [icon]="'back-arrow'"
            [iconPosition]="'right'"
            [style]="'link'"
            [href]="'purchase/custom' | formatUrl | async"
        >
            {{ 'vg_landing_purchase_payment_btn-edit-gift-text' | translate }}
        </vg-button>
    </div>
</vg-card-giftcard>
