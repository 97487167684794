import { Observable } from 'rxjs';
import { CardToken } from '../models/CardToken';
import { CardSchema } from '../models/CardSchema';
import { PurchaseCustomModel } from '../models/PurchaseCustomModel';

export abstract class RecurlyRepository {
    abstract createCardToken(card: {
        key?: string;
        number: string;
        first_name: string;
        last_name: string;
        address1: string;
        city: string;
        state: string;
        postal_code: string;
        country: 'MX';
        month: string;
        year: string;
        cvv: string;
        version?: string;
    }): Observable<CardToken>;
    abstract getCardSchema(card: { bin: string }): Observable<CardSchema>;
    abstract createPurchaseCustom(
        purchaseCustomModel: PurchaseCustomModel,
    ): Observable<unknown>;
}
