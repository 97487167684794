import { CheckLoginUseCase } from '@/core/domain/auth/usecases/check-login.usecase';
import { PostLoginUseCase } from '@/core/domain/auth/usecases/post-login.usecase';
import { GtmLoginSuccess } from '@/core/domain/gtm/models/gtm-login-successful.models';
import { SetDataLayerParamsUseCase } from '@/core/domain/gtm/usecases/setDataLayer.usecase';
import { ModalErrorOpenUseCase } from '@/core/domain/modal-error/usecases/modal-error-open.usecase';
import { updateFromSSOData } from '@/data/auth/store/from-sso.actions';
import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    ResolveFn,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { catchError, from, of, take } from 'rxjs';

export const SingleSignOnQueryParamResolver: ResolveFn<unknown> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    const modalError = inject(ModalErrorOpenUseCase);
    const authPostLogin = inject(PostLoginUseCase);
    const authCheckLogin = inject(CheckLoginUseCase);
    const gtmDataLayer = inject(SetDataLayerParamsUseCase);
    const router = inject(Router);
    const store = inject(Store);

    const qp = state.root.queryParams as any;

    const ParamKeys = Object.keys(qp);

    if (ParamKeys.length === 0) return of(null);

    const segments = state.url.split('?')[0].split('/');
    if (ParamKeys.includes('error')) {
        return router
            .navigate(segments, {
                queryParams: {},
                queryParamsHandling: 'merge',
                replaceUrl: true,
            })
            .then(() => {
                modalError.execute({
                    error: qp.error,
                    description: qp.error_description,
                    redirectSSO: false,
                });
                return of(null);
            });
    }

    if (
        ParamKeys.includes('Referer') ||
        !ParamKeys.includes('activation_code')
    ) {
        return of(null);
    }

    store.dispatch(updateFromSSOData({ value: true }));

    // TODO: MOVE OR CHANGE THIS DATA LAYER
    gtmDataLayer.execute<GtmLoginSuccess>({
        event: 'ev_login',
        category: 'profile',
        action: 'login',
        label: 'successful',
        sso_login: 'Yes',
        login: {
            status: 'successful',
            method: 'NotLinked',
            flow: null,
        },
        detail: {
            module: 'login',
            component: 'signin',
            section: ['sideMenu'],
            element: 'system',
            action: 'signIn',
            description: { status: 'successful' },
        },
        id: { v: qp['activation_code'] },
    });

    return from(
        router.navigate(segments, {
            queryParams: {},
            queryParamsHandling: 'merge',
            replaceUrl: true,
        }),
    ).subscribe(() => {
        authCheckLogin
            .execute()
            .pipe(
                take(1),
                catchError((error) => {
                    // Handle error if necessary
                    console.error('Error storing tokens:', error);
                    return of({});
                }),
            )
            .subscribe((auth) => {
                if (!auth) {
                    authPostLogin
                        .execute(qp)
                        .pipe(
                            catchError((error) => {
                                modalError.execute({
                                    error: error.code,
                                    description: error.message,
                                    redirectSSO: false,
                                });
                                return of(null);
                            }),
                        )
                        .subscribe();
                }
            })
            .unsubscribe();
    });
};
