import { TranslateService } from '@ngx-translate/core';
export interface ErrorCode {
    title: string;
    text: string;
    btnTitle: string;
}
export type ErrorCodesMap = {
    [key: string]: ErrorCode;
};
export const setErrorCodes = (translate: TranslateService): ErrorCodesMap => {
    return {
        GIFT_CARD_NOT_FOUND: {
            title: translate.instant(
                'vg_redeem_error_gift_card_not_found-title',
            ),
            text: translate.instant('vg_redeem_error_gift_card_not_found-text'),
            btnTitle: translate.instant(
                'vg_redeem_error_gift_card_not_found-btn-title',
            ),
        },
        GIFT_CARD_EXPIRED: {
            title: translate.instant('vg_redeem_error_gift_card_expired-title'),
            text: translate.instant('vg_redeem_error_gift_card_expired-text'),
            btnTitle: translate.instant(
                'vg_redeem_error_gift_card_expired-btn-title',
            ),
        },
        GIFT_CARD_REDEEMED: {
            title: translate.instant(
                'vg_redeem_error_gift_card_redeemed-title',
            ),
            text: translate.instant('vg_redeem_error_gift_card_redeemed-text'),
            btnTitle: translate.instant(
                'vg_redeem_error_gift_card_redeemed-btn-title',
            ),
        },
        GIFT_CARD_INVALID: {
            title: translate.instant('vg_redeem_error_gift_card_invalid-title'),
            text: translate.instant('vg_redeem_error_gift_card_invalid-text'),
            btnTitle: translate.instant(
                'vg_redeem_error_gift_card_invalid-btn-title',
            ),
        },
        GIFT_CARD_REDEMPTION_NOT_ALLOWED: {
            title: translate.instant(
                'vg_redeem_error_gift_card_redemption_not_allowed-title',
            ),
            text: translate.instant(
                'vg_redeem_error_gift_card_redemption_not_allowed-text',
            ),
            btnTitle: translate.instant(
                'vg_redeem_error_gift_card_redemption_not_allowed-btn-title',
            ),
        },
        INVALID_AUTHORIZATION: {
            title: translate.instant(
                'vg_redeem_error_invalid_authorization-title',
            ),
            text: translate.instant(
                'vg_redeem_error_invalid_authorization-text',
            ),
            btnTitle: translate.instant(
                'vg_redeem_error_invalid_authorization-btn-title',
            ),
        },
        INVALID_CHANNEL_CODE: {
            title: translate.instant(
                'vg_redeem_error_invalid_channel_code-title',
            ),
            text: translate.instant(
                'vg_redeem_error_invalid_channel_code-text',
            ),
            btnTitle: translate.instant(
                'vg_redeem_error_invalid_channel_code-btn-title',
            ),
        },
        GENERIC_VALIDATION_ERROR: {
            title: translate.instant(
                'vg_redeem_error_generic_validation_error-title',
            ),
            text: translate.instant(
                'vg_redeem_error_generic_validation_error-text',
            ),
            btnTitle: translate.instant(
                'vg_redeem_error_generic_validation_error-btn-title',
            ),
        },
        DEFAULT_ERROR: {
            title: translate.instant('vg_redeem_error_default-title'),
            text: translate.instant('vg_redeem_error_default-text'),
            btnTitle: translate.instant('vg_redeem_error_default-btn-title'),
        },
    };
};
