import { openModalError } from './../../../../data/modal-error/store/modal-error.actions';
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @ngrx/prefer-selector-in-select */

import { LoginUseCase } from '@/core/domain/auth/usecases/login.usecase';
import { ModalErrorModel } from '@/core/domain/modal-error/models/modal-error.model';
import { ModalErrorCloseUseCase } from '@/core/domain/modal-error/usecases/modal-error-close.usecase';
import { ModalErrorOpenUseCase } from '@/core/domain/modal-error/usecases/modal-error-open.usecase';
import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { of, switchMap } from 'rxjs';

@Component({
    selector: 'vg-modal-sso-error',
    templateUrl: './modal-sso-error.component.html',
    styleUrls: ['./modal-sso-error.component.scss'],
})
export class ModalSingleSignOnErrorComponent implements OnInit, AfterViewInit {
    @ViewChild('Modal') $modal!: ElementRef<HTMLDivElement>;

    private isOpen = false;

    public redirectSSO = false;

    constructor(
        private readonly store: Store<{
            modalError: ModalErrorModel;
        }>,
        private readonly openModal: ModalErrorOpenUseCase,
        private readonly closeModal: ModalErrorCloseUseCase,
        private readonly renderer2: Renderer2,
        private readonly authLogin: LoginUseCase,
    ) {}

    ngOnInit(): void {
        this.initModal();
    }

    public login(): void {
        this.store.dispatch(
            openModalError({
                data: {
                    isOpen: false,
                    description: '',
                    error: '',
                    redirectSSO: false,
                },
            }),
        );
        this.authLogin.execute();
    }

    private initModal() {
        this.store
            .select((state) => state.modalError.isOpen)
            .pipe(
                switchMap((isOpen) => {
                    this.isOpen = isOpen;
                    return of(isOpen);
                }),
            )
            .subscribe();
        this.store
            .select((state) => state.modalError.redirectSSO)
            .pipe(
                switchMap((redirectSSO) => {
                    this.redirectSSO = redirectSSO;
                    return of(redirectSSO);
                }),
            )
            .subscribe();
    }

    ngAfterViewInit(): void {
        if (this.$modal.nativeElement && this.isOpen) {
            this.renderer2.addClass(this.$modal.nativeElement, 'show');
        }
    }

    public setCloseModal() {
        if (this.$modal.nativeElement) {
            this.isOpen = false;
            this.closeModal.execute();
            this.renderer2.removeClass(this.$modal.nativeElement, 'show');
        }
    }
}
