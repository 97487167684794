import { Component, HostListener, Input, OnInit } from '@angular/core';
@Component({
    selector: 'vg-card-steps',
    templateUrl: './card-steps.component.html',
    styleUrls: ['./card-steps.component.scss'],
})
export class CardStepsComponent implements OnInit {
    @Input() title!: string;
    @Input() page!: string;
    @Input() subTitle!: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() stepsInfo: any[] = [];
    @Input() showSwiper!: boolean;

    isMobile = false;

    ngOnInit(): void {
        this.getScreenSize(window.innerWidth);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: Event) {
        const windowWidth = (event.target as Window).innerWidth;
        this.getScreenSize(windowWidth);
    }

    getScreenSize(size: number) {
        this.isMobile = size <= 768;
    }
}
