import { Observable } from 'rxjs';
import { Pipe, PipeTransform } from '@angular/core';
import { UrlService } from '@/common/url/url.service';

@Pipe({
    name: 'formatUrl',
})
export class FormatUrlPipe implements PipeTransform {
    constructor(private readonly url: UrlService) {}
    transform(resource: string): Observable<string> {
        return this.url.format(resource);
    }
}
