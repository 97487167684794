import { OrdersRepository } from '@/core/domain/orders/repositories/orders.repository';
import { GetOrdersParamsUseCase } from '@/core/domain/orders/usecases/getOrders-loader.usecase';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OrdersImplRepository } from './orders-impl.repository';
import { GetCardPDFParamsUseCase } from '@/core/domain/orders/usecases/getCardPDF-loader.usecase';

const ordersLoaderUseCaseFactory = (ordersRepository: OrdersRepository) =>
    new GetOrdersParamsUseCase(ordersRepository);

const getCardPDFUseCaseFactory = (ordersRepository: OrdersRepository) =>
    new GetCardPDFParamsUseCase(ordersRepository);

@NgModule({
    imports: [CommonModule],
    providers: [
        {
            provide: GetOrdersParamsUseCase,
            useFactory: ordersLoaderUseCaseFactory,
            deps: [OrdersRepository],
        },
        {
            provide: GetCardPDFParamsUseCase,
            useFactory: getCardPDFUseCaseFactory,
            deps: [OrdersImplRepository],
        },
        {
            provide: OrdersRepository,
            useClass: OrdersImplRepository,
        },
    ],
})
export class OrdersDataModule {}
