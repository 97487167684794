import { OrdersReceivedRepository } from '@/core/domain/orders-received/repositories/orders-received.repository';
import { GetOrdersReceivedParamsUseCase } from '@/core/domain/orders-received/usecases/getOrdersReceived-loader.usecase';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OrdersReceivedImplRepository } from './orders-received-impl.repository';

const ordersReceivedLoaderUseCaseFactory = (
    ordersReceivedRepository: OrdersReceivedRepository,
) => new GetOrdersReceivedParamsUseCase(ordersReceivedRepository);

@NgModule({
    imports: [CommonModule],
    providers: [
        {
            provide: GetOrdersReceivedParamsUseCase,
            useFactory: ordersReceivedLoaderUseCaseFactory,
            deps: [OrdersReceivedRepository],
        },
        {
            provide: OrdersReceivedRepository,
            useClass: OrdersReceivedImplRepository,
        },
    ],
})
export class OrdersReceivedDataModule {}
