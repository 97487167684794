import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatNumberPipe } from './formatNumber/format-number.pipe';
import { ReplacePipe } from './replace/replace.pipe';
import { FormatUrlPipe } from './format-url/format-url.pipe';
import { TranslationsDataModule } from '../../data/translation/translations-data.module';
import { CdnPipe } from './cdn/cdn.pipe';
import { SingleTranslationPipe } from './single-translation/single-translation.pipe';

@NgModule({
    declarations: [
        FormatNumberPipe,
        ReplacePipe,
        FormatUrlPipe,
        CdnPipe,
        SingleTranslationPipe,
    ],
    imports: [CommonModule, TranslationsDataModule],
    exports: [
        FormatNumberPipe,
        ReplacePipe,
        FormatUrlPipe,
        CdnPipe,
        SingleTranslationPipe,
    ],
})
export class PipesModule {}
