import { UseCase } from '@/core/base/use-case';
import { Observable } from 'rxjs';
import { CardToken } from '../models/CardToken';
import { RecurlyRepository } from '../repositories/recurly.repository';
import { CardSchema } from '../models/CardSchema';

export interface GetCardSchemaUseCaseInput {
    bin: string;
}

export class GetCardSchemaUseCase
    implements UseCase<GetCardSchemaUseCaseInput, CardToken>
{
    constructor(private readonly repository: RecurlyRepository) {}

    execute(params: GetCardSchemaUseCaseInput): Observable<CardSchema> {
        return this.repository.getCardSchema(params);
    }
}
