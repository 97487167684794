import { AuthModuleConfig } from '@/core/domain/auth/models/auth-module.config';
import { AuthRepository } from '@/core/domain/auth/repositories/auth.repository';
import { AuthApi } from '@/core/domain/auth/services/auth.api';
import { SingleSignOnApi } from '@/core/domain/auth/services/sso.api';
import { CheckLoginUseCase } from '@/core/domain/auth/usecases/check-login.usecase';
import { CheckLoginExternalStatusUseCase } from '@/core/domain/auth/usecases/check-loginexternal-status.usecase';
import { LoginUseCase } from '@/core/domain/auth/usecases/login.usecase';
import { LogoutUseCase } from '@/core/domain/auth/usecases/logout.usecase';
import { PostLoginUseCase } from '@/core/domain/auth/usecases/post-login.usecase';
import { RestoreUseCase } from '@/core/domain/auth/usecases/restore.usecase';
import { HttpClient } from '@angular/common/http';
import {
    ModuleWithProviders,
    NgModule,
    Optional,
    SkipSelf,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
    FOR_ROOT_AUTH_OPTIONS_TOKEN,
    ImplementationRepositoryFactory,
} from './auth-data.aot';
import { EzyService } from './services/ezy/ezy.service';
import { SingleSignOnService } from './services/sso/sso.service';

@NgModule({
    imports: [],
})
export class AuthDataModule {
    constructor(@Optional() @SkipSelf() parentModule: AuthDataModule) {
        if (parentModule) {
            throw new Error(
                'AuthDataModule is already loaded. Import it in the AppModule only',
            );
        }
    }

    static forRoot(
        config: AuthModuleConfig,
    ): ModuleWithProviders<AuthDataModule> {
        return {
            ngModule: AuthDataModule,
            providers: [
                // It provides the configuration in the module context.
                // Helps to set the configuration around the useCases and
                // services by dependency injection.
                {
                    provide: FOR_ROOT_AUTH_OPTIONS_TOKEN,
                    useValue: config,
                },
                {
                    provide: AuthApi,
                    useClass: EzyService,
                    deps: [HttpClient],
                },
                {
                    provide: SingleSignOnApi,
                    useClass: SingleSignOnService,
                    deps: [HttpClient],
                },
                {
                    provide: AuthRepository,
                    useFactory: ImplementationRepositoryFactory,
                    deps: [
                        Store,
                        AuthApi,
                        SingleSignOnApi,
                        FOR_ROOT_AUTH_OPTIONS_TOKEN,
                        Router,
                    ],
                },
                // ------------------------------------------------------
                // -------------------- | UseCases | --------------------
                // ------------------------------------------------------
                {
                    provide: LoginUseCase,
                    useFactory: (repo: AuthRepository) =>
                        new LoginUseCase(repo),
                    deps: [AuthRepository],
                },
                {
                    provide: PostLoginUseCase,
                    useFactory: (repo: AuthRepository) =>
                        new PostLoginUseCase(repo),
                    deps: [AuthRepository],
                },
                {
                    provide: CheckLoginUseCase,
                    useFactory: (repo: AuthRepository) =>
                        new CheckLoginUseCase(repo),
                    deps: [AuthRepository],
                },
                {
                    provide: LogoutUseCase,
                    useFactory: (repo: AuthRepository) =>
                        new LogoutUseCase(repo),
                    deps: [AuthRepository],
                },
                {
                    provide: RestoreUseCase,
                    useFactory: (repo: AuthRepository) =>
                        new RestoreUseCase(repo),
                    deps: [AuthRepository],
                },
                {
                    provide: CheckLoginExternalStatusUseCase,
                    useFactory: (repo: AuthRepository) =>
                        new CheckLoginExternalStatusUseCase(repo),
                    deps: [AuthRepository],
                },
            ],
        };
    }
}
