import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SingleSignOnQueryParamResolver } from './resolvers/sso.resolver';
import { LanguageGuard } from './language.guard';
import { CompensationResolver } from './resolvers/compensation.resolver';
import { getLanguage } from '@/common/utils/strings/path.utils';
import { environment } from '@/env/environment';

const routes: Routes = [
    {
        path: environment.production ? '' : ':language/vivagift',
        loadChildren: () =>
            import('./pages/pages.module').then((c) => c.PagesModule),
        // canActivate: [LanguageGuard],
        resolve: {
            queryParams: SingleSignOnQueryParamResolver,
            compensations: CompensationResolver,
        },
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: environment.production
            ? ''
            : '/' + getLanguage() + '/vivagift',
        resolve: {
            queryParams: SingleSignOnQueryParamResolver,
            compensations: CompensationResolver,
        },
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            scrollOffset: [0, 70],
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
