import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'vg-modal-threeds',
    templateUrl: './modal-threeds.component.html',
    styleUrls: ['./modal-threeds.component.scss'],
})
export class ModalThreedsComponent {
    @Input() show = false;
    @Output() closeEvent = new EventEmitter();
    public sendClose(): void {
        this.closeEvent.emit();
    }
}
