import { Injectable } from '@angular/core';
import {
    EMPTY,
    Observable,
    catchError,
    concat,
    filter,
    of,
    switchMap,
    throwError,
} from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
    OrdersReceivedModel,
    OrdersReceivedModelKeys,
} from '@/core/domain/orders-received/models/orders-received.model';
import { OrdersReceivedRepository } from '@/core/domain/orders-received/repositories/orders-received.repository';
import { environment } from '@/env/environment';
import { CheckLoginUseCase } from '@/core/domain/auth/usecases/check-login.usecase';
@Injectable({
    providedIn: 'root',
})
export class OrdersReceivedImplRepository extends OrdersReceivedRepository {
    constructor(
        private http: HttpClient,
        private readonly authCheckLogin: CheckLoginUseCase,
    ) {
        super();
    }

    override getOrdersReceived<T>(
        config: OrdersReceivedModelKeys,
    ): Observable<T> {
        return this.authCheckLogin.execute().pipe(
            switchMap((auth) => {
                if (!auth) {
                    throwError(() => new Error('User is not logged'));
                    return of();
                }

                // TODO: MOVE THIS LOGIC INTO A SERVICE
                const headers = new HttpHeaders({
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.accessToken}`,
                    'X-Channel': 'web',
                });

                return concat(
                    this.http.get(
                        environment.vgApi.BASE_HOST +
                            environment.vgApi.ENDPOINTS.getReceived,
                        { headers: headers },
                    ),
                ).pipe(
                    filter((response: any) => response != null),
                    switchMap((response: OrdersReceivedModel) => {
                        const configData = response[config];
                        return of(configData as T);
                    }),
                    catchError(() => of([] as T)),
                );
            }),
        );
    }
}
