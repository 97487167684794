import { Injectable } from '@angular/core';
import {
    EMPTY,
    Observable,
    catchError,
    concat,
    filter,
    of,
    switchMap,
    throwError,
} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
    OrdersModel,
    OrdersModelKeys,
} from '@/core/domain/orders/models/orders.model';
import { OrdersRepository } from '@/core/domain/orders/repositories/orders.repository';
import { environment } from '@/env/environment';
import { CheckLoginUseCase } from '@/core/domain/auth/usecases/check-login.usecase';

@Injectable({
    providedIn: 'root',
})
export class OrdersImplRepository extends OrdersRepository {
    constructor(
        private http: HttpClient,
        private readonly authCheckLogin: CheckLoginUseCase,
    ) {
        super();
    }

    override getOrders<T>(config: OrdersModelKeys): Observable<T> {
        return this.authCheckLogin.execute().pipe(
            switchMap((auth) => {
                if (!auth) {
                    throwError(() => new Error('User is not logged'));
                    return of();
                }

                // TODO: MOVE THIS LOGIC INTO A SERVICE
                const headers = new HttpHeaders({
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.accessToken}`,
                    'X-Channel': 'web',
                });

                return concat(
                    this.http.get(
                        environment.vgApi.BASE_HOST +
                            environment.vgApi.ENDPOINTS.getPurchased,
                        { headers: headers },
                    ),
                ).pipe(
                    filter((response: any) => response != null),
                    switchMap((response: OrdersModel) => {
                        const configData = response[config];
                        return of(configData as T);
                    }),
                    catchError(() => of([] as T)),
                );
            }),
        );
    }

    override getCardPDF<T>(config: string): Observable<T> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Channel': 'web',
        });

        return concat(
            //TODO: agregar funcionalidad de variables de entornos para endpoints
            this.http.get(
                `${
                    environment.vgApi.BASE_HOST +
                    environment.vgApi.ENDPOINTS.getPDF
                }${config}`,
                { headers: headers },
            ),
        ).pipe(
            filter((response: any) => response != null),
            switchMap((response: any) => {
                const configData = response[config];
                return of(configData as T);
            }),
            catchError(() => EMPTY),
        );
    }
}
