import { CardRedeemRepository } from '@/core/domain/card-redeem/repositories/card-redeem.repository';
import {
    PostCardRedeemUseCase,
    PostCardRedeemB2BUseCase,
    PostCardRedeemMPUseCase,
} from '../../core/domain/card-redeem/usecases/postCardRedeem-loader.usecase';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardRedeemImplRepository } from './card-redeem-impl.repository';

const cardRedeemLoaderUseCaseFactory = (
    CardRedeemRepository: CardRedeemRepository,
) => new PostCardRedeemUseCase(CardRedeemRepository);

const cardRedeemB2BLoaderUseCaseFactory = (
    CardRedeemRepository: CardRedeemRepository,
) => new PostCardRedeemB2BUseCase(CardRedeemRepository);

const cardRedeemMPLoaderUseCaseFactory = (
    CardRedeemRepository: CardRedeemRepository,
) => new PostCardRedeemMPUseCase(CardRedeemRepository);

@NgModule({
    imports: [CommonModule],
    providers: [
        {
            provide: PostCardRedeemUseCase,
            useFactory: cardRedeemLoaderUseCaseFactory,
            deps: [CardRedeemRepository],
        },
        {
            provide: PostCardRedeemB2BUseCase,
            useFactory: cardRedeemB2BLoaderUseCaseFactory,
            deps: [CardRedeemRepository],
        },
        {
            provide: PostCardRedeemMPUseCase,
            useFactory: cardRedeemMPLoaderUseCaseFactory,
            deps: [CardRedeemRepository],
        },
        {
            provide: CardRedeemRepository,
            useClass: CardRedeemImplRepository,
        },
    ],
})
export class CardRedeemDataModule {}
