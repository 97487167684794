import { UseCase } from '@/core/base/use-case';
import { Observable } from 'rxjs';
import { OrdersModelKeys } from '../models/orders.model';
import { OrdersRepository } from '../repositories/orders.repository';

export type LoaderOrdersUseCaseInput = OrdersModelKeys;

export class GetOrdersParamsUseCase<T>
    implements UseCase<LoaderOrdersUseCaseInput, T>
{
    constructor(private readonly repository: OrdersRepository) {}

    execute(param: LoaderOrdersUseCaseInput): Observable<T> {
        return this.repository.getOrders<T>(param);
    }
}
