import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'vg-card-faq',
    templateUrl: './card-faq.component.html',
    styleUrls: ['./card-faq.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CardFaqComponent {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() faqs: any[] = [];
}
