<vg-card>
    <h4 class="title">
        {{ 'vg_landing_purchase_payment_mailing-h4-title' | translate }}
    </h4>
    <div class="instructions font-family-lato">
        {{ 'vg_landing_purchase_payment_mailing-instructions' | translate }}
    </div>
    <div class="container container-info-mailing px-0">
        <div class="row info-mailing-title info-mailing-value">
            <div class="col-4 col-md-12 col-lg-4">
                <dl>
                    <dt class="title">
                        {{
                            'vg_landing_purchase_payment_mailing-receiver-label'
                                | translate
                        }}
                    </dt>
                    <dd class="font-family-lato">
                        {{ purchasePersistence?.shipment?.recipientName }}
                    </dd>
                </dl>
            </div>
            <div class="col-8 col-md-12 col-lg-8">
                <dl>
                    <dt class="title">
                        {{
                            'vg_landing_purchase_payment_mailing-mail-label'
                                | translate
                        }}
                    </dt>
                    <dd class="font-family-lato">
                        {{ purchasePersistence?.shipment?.recipientEmail }}
                    </dd>
                </dl>
            </div>
        </div>
        <div class="row info-mailing-title info-mailing-value">
            <div class="col-4 col-md-12 col-lg-4">
                <dl>
                    <dt class="title">
                        {{
                            'vg_landing_purchase_payment_mailing-date-send-label'
                                | translate
                        }}
                    </dt>
                    <dd class="font-family-lato">
                        {{
                            purchasePersistence?.shipment
                                ?.recipientScheduleShipping
                                | date: 'dd MMM YYYY' : undefined : lang
                        }}
                    </dd>
                </dl>
            </div>
            <div class="col-8 col-md-12 col-lg-8">
                <dl>
                    <dt class="title">
                        {{
                            'vg_landing_purchase_payment_mailing-date-expiration-label'
                                | translate
                        }}
                    </dt>
                    <dd class="font-family-lato">
                        {{
                            'vg_landing_purchase_payment_mailing-date-expiration-value'
                                | translate
                        }}
                    </dd>
                </dl>
            </div>
        </div>
    </div>
    <hr class="seperation-mailing" />
    <div class="title">
        {{ 'vg_landing_purchase_payment_mailing-summary-title' | translate }}
    </div>
    <div class="container container-prices-summary px-0">
        <div class="row prices-summary-title justify-content-between">
            <div class="col-6">
                {{
                    'vg_landing_purchase_payment_mailing-summary-vivagift'
                        | translate
                }}
            </div>
            <div class="col-6 text-end">
                {{ purchasePersistence?.amount | formatNumber }} mxn
            </div>
        </div>
        <div class="row prices-summary-value justify-content-between">
            <div class="col-6">
                {{
                    'vg_landing_purchase_payment_mailing-summary-total'
                        | translate
                }}
            </div>
            <div class="col-6 text-end">
                {{ purchasePersistence?.amount | formatNumber }} mxn
            </div>
        </div>
    </div>
</vg-card>
