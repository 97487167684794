import { createReducer, on } from '@ngrx/store';
import {
    deletePurchasePersistenceAction,
    savePurchasePersistenceAction,
} from './purchase-persistence.actions';
import { saveReducer } from './purchase-persistence.reducer';

export const PurchasePersistenceReducer = createReducer(
    {},
    on(savePurchasePersistenceAction, saveReducer),
    // eslint-disable-next-line @ngrx/on-function-explicit-return-type
    on(deletePurchasePersistenceAction, () => {
        return {};
    }),
);
