<!-- Slider main container -->
<div [ngClass]="type + ' swiper-' + uniqClass" class="swiper">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
        <!-- Slides -->
        <ng-content></ng-content>
    </div>
    <!-- If we need pagination -->
    <div
        *ngIf="showPagination"
        class="swiper-pagination swiper-pagination-clickable"
    ></div>

    <!-- If we need navigation buttons -->
    <div *ngIf="showBtnNavigation" class="swiper-button-prev">
        <img
            [src]="'vg/images/arrow_green.svg' | cdn"
            alt="arrow_green"
            class="card-redeem-img"
        />
    </div>
    <div *ngIf="showBtnNavigation" class="swiper-button-next">
        <img
            [src]="'vg/images/arrow_green.svg' | cdn"
            alt="arrow_green"
            class="card-redeem-img"
        />
    </div>
</div>
