import { UseCase } from '@/core/base/use-case';
import { Observable } from 'rxjs';
import { OrdersCompensationModelKeys } from '../models/orders-compensation.model';
import { OrdersCompensationRepository } from '../repositories/orders-compensation.repository';

export type LoaderOrdersCompensationUseCaseInput = OrdersCompensationModelKeys;

export class GetOrdersCompensationParamsUseCase<T>
    implements UseCase<LoaderOrdersCompensationUseCaseInput, T>
{
    constructor(private readonly repository: OrdersCompensationRepository) {}

    execute(param: LoaderOrdersCompensationUseCaseInput): Observable<T> {
        return this.repository.getOrdersCompensation<T>(param);
    }
}
