import { UseCase } from '@/core/base/use-case';
import { Observable } from 'rxjs';
import { ModalErrorRepository } from '../repositories/modal-error.repository';

export class ModalErrorCloseUseCase implements UseCase<void, never> {
    constructor(private readonly repository: ModalErrorRepository) {}

    execute(): Observable<never> {
        return this.repository.closeErrorModal();
    }
}
