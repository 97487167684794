import { createReducer, on } from '@ngrx/store';
import { updateLoadingUser } from './loading-user.actions';

export const updateLoadingUserReducer = (
    _: boolean,
    { value }: { value: boolean },
): boolean => value;

export const LoadingUserReducer = createReducer(
    false,
    on(updateLoadingUser, updateLoadingUserReducer),
);
