import { Action, createReducer, on } from '@ngrx/store';
import { AuthUserData } from './models/auth.store';
import * as actions from './auth.actions';
import { UserDetails } from '../services/ezy/responses/interfaces/user-details.interface';

export const AUTH_INITIAL_STATE: AuthUserData = {
    accessToken: '',
    authToken: '',
    expiration: 0,
    session: '',
    isLogged: false,
    details: {} as UserDetails,
};

export const _authReducer = createReducer<AuthUserData>(
    AUTH_INITIAL_STATE,
    on(actions.resetToken, (): AuthUserData => AUTH_INITIAL_STATE),
    on(
        actions.updateToken,
        (state, { tokens }): AuthUserData => ({ ...state, ...tokens }),
    ),
);

export const AuthReducer = (
    state: AuthUserData | undefined,
    action: Action,
) => {
    return _authReducer(state, action);
};
