import { UrlService } from '@/common/url/url.service';
import { environment } from '@/env/environment';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'vg-card-giftcard-code',
    templateUrl: './vg-card-giftcard-code.component.html',
    styleUrls: ['./vg-card-giftcard-code.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VgCardGiftcardCodeComponent {
    @Input() card: any = [];
    isb2bCard = false;

    linkPDF = environment.vgApi.BASE_HOST + environment.vgApi.ENDPOINTS.getPDF;

    defaultCardDesign = '/vg/cards/default_card.svg';

    constructor(
        private readonly router: Router,
        private readonly url: UrlService,
    ) {
        const info = this.router.getCurrentNavigation()?.extras.state;
        this.card = info;
        if (!this.card) {
            this.url
                .format('dashboard/gifts')
                .subscribe((url) => this.router.navigateByUrl(url));
        }
        this.isb2bCard = this.b2bCardValidation(this.card.code, ['BG']);
    }

    public getPDF() {
        window.open(this.linkPDF + this.card.code, '_blank');
    }

    public b2bCardValidation(code: string, options: string[]): boolean {
        const firstTwoLetters: string = code.substring(0, 2);
        return code.length >= 2 && options.includes(firstTwoLetters);
    }
}
