import { UseCase } from '@/core/base/use-case';
import { Observable } from 'rxjs';
import { AuthRepository } from '../repositories/auth.repository';

export type LoginUseCaseInput = {
    path?: string;
};

export class LoginUseCase implements UseCase<LoginUseCaseInput, void> {
    constructor(private readonly repository: AuthRepository) {}

    execute(params?: LoginUseCaseInput): Observable<void> {
        return this.repository.login(params);
    }
}
