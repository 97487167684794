<div [ngClass]="'container-' + page" class="container text-center card-steps">
    <h4 *ngIf="title" class="title-steps">{{ title }}</h4>
    <p *ngIf="title" class="sub-title-steps" [innerHtml]="subTitle"></p>
    <h3 *ngIf="!title" class="sub-title-steps" [innerHtml]="subTitle"></h3>
    <div *ngIf="!showSwiper || (!isMobile && showSwiper)" class="row">
        <div [ngClass]="page" class="col-lg" *ngFor="let step of stepsInfo">
            <div class="img-step">
                <img [src]="step.image" [alt]="step.title" />
            </div>
            <div class="text">
                <p [innerHTML]="step.title | translate" class="title-image"></p>
                <p
                    [innerHTML]="step.text | translate"
                    class="info-text-step m-0 font-family-lato"
                ></p>
            </div>
        </div>
    </div>
    <div *ngIf="isMobile && showSwiper">
        <vg-swiper [type]="'step'">
            <div
                [ngClass]="page"
                class="swiper-slide"
                *ngFor="let step of stepsInfo"
            >
                <div class="content-info-step">
                    <img [src]="step.image" [alt]="step.title" />
                    <div class="text m-auto">
                        <h3 [innerHTML]="step.title | translate"></h3>
                        <p
                            [innerHTML]="step.text | translate"
                            class="info-text-step m-0 font-family-lato"
                        ></p>
                    </div>
                </div>
            </div>
        </vg-swiper>
    </div>
</div>
