import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';

export const REG_EXP_WITHOUT_SPECIAL_CHAR_NUMBER = /^[a-zA-Z\s]+$/;

@Injectable({
    providedIn: 'root',
})
export class ValidationService {
    public withoutSpecialCharNumber(
        control: FormControl,
    ): { [s: string]: boolean } | null {
        if (typeof control.value !== 'string' || control.value.length === 0) {
            return null;
        }
        if (!RegExp(REG_EXP_WITHOUT_SPECIAL_CHAR_NUMBER).exec(control.value)) {
            return { specialCharOrNumberNotAllowed: true };
        }
        return null;
    }

    public mismatchingValue(toMistmachValue: string): ValidatorFn {
        return (c: AbstractControl) => {
            if (c == null || c.value == null) return null;
            if (c.value !== toMistmachValue) {
                return null;
            }
            return {
                matchedValues: {
                    currentValue: c.value,
                    toMistmachValue: toMistmachValue,
                },
            };
        };
    }
}
