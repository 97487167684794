import { UseCase } from '@/core/base/use-case';
import { AuthUserData } from '@/data/auth/store/models/auth.store';
import { Observable } from 'rxjs';
import { AuthRepository } from '../repositories/auth.repository';

export class RestoreUseCase implements UseCase<void, AuthUserData | null> {
    constructor(private readonly repository: AuthRepository) {}

    execute(params: void): Observable<AuthUserData | null> {
        return this.repository.restore();
    }
}
