import { Card } from '@/core/domain/orders/models/orders.model';
import { GetOrdersParamsUseCase } from '@/core/domain/orders/usecases/getOrders-loader.usecase';
import { Component } from '@angular/core';

@Component({
    selector: 'vg-card-details',
    templateUrl: './card-details.component.html',
    styleUrls: ['./card-details.component.scss'],
})
export class CardDetailsComponent {
    cards: Card[] = [];
    receiverName = 'Carlos Jimenez';
    receiverEmail = 'carlos.jimenez@gmail.com';
    receiverDate = '23 abr 2022';
    expirationDate = '23 abr 2023';
    status = 'En revisión';
    instructions =
        'El código de regalo llegará a la bandeja de correo de tu destinatario con las instrucciones para canjearlo en Viva Cash para comprar en Viva Aerobus';
    amount = '$1000';
    order = '#00323222';
    giftcardCode = '0000-0000-0000-0000';
    cardTermination = 'Tarjeta terminación 3456';
    cardInformation =
        'Laura Hernández Salcedo Reforma 34, Cuauhtémoc, Ciudad de México, 07600';
}
