import { UseCase } from '@/core/base/use-case';
import { Observable } from 'rxjs';
import { AuthData } from '../models/auth.data';
import { AuthRepository } from '../repositories/auth.repository';

export interface ResponsePostLogin {
    type: string;
    action: string;
}

export class PostLoginUseCase implements UseCase<AuthData, ResponsePostLogin> {
    constructor(private readonly repository: AuthRepository) {}

    execute(params: AuthData): Observable<ResponsePostLogin> {
        return this.repository.postLogin(params);
    }
}
