import { Observable } from 'rxjs';
import { TranslationModel } from '../models/translation.model';
import { LanguageModel } from '../models/language.model';

export abstract class TranslationsRepository {
    abstract load(lang: string, reload: boolean): Observable<TranslationModel>;
    abstract getLanguague(): Observable<LanguageModel>;
    abstract getLanguague(): Observable<LanguageModel>;
    abstract translateRoutes(): Observable<string>;
}
