import {
    CardAmounts,
    CardDesign,
} from '@/core/domain/configurations/models/configurations.model';
import { GetConfigParamsUseCase } from '../../../../core/domain/configurations/usecases/getConfigParams-loader.usecase';
import {
    Component,
    ViewEncapsulation,
    EventEmitter,
    Output,
    Input,
    OnInit,
} from '@angular/core';
import { SavePurchaseUseCase } from '@/core/domain/purchase-persistence/usecases/save-purchase-persistence.usecase';
import { GetPurchaseUseCase } from '@/core/domain/purchase-persistence/usecases/get-purchase-persistence.usecase';
import { switchMap } from 'rxjs';
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';

export const ANIMATIONS_CARD = [
    trigger('fadeInOut', [
        state(
            'void',
            style({
                opacity: 0,
                transform: 'translateY(-20px)',
            }),
        ),
        transition(':enter, :leave', [animate('0.3s ease-in-out')]),
    ]),
];

@Component({
    selector: 'vg-card-amount',
    templateUrl: './card-amount.component.html',
    styleUrls: ['./card-amount.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: ANIMATIONS_CARD,
})
export class CardAmountComponent implements OnInit {
    amounts$: number[] = [];
    min = 0;
    max = 0;
    step = 0;
    cardAmount = 500;
    @Output() newItemEvent = new EventEmitter<string>();

    @Output() selectedCardAmount = new EventEmitter();

    @Input() setCard: CardDesign | null = null;

    constructor(
        private readonly getCardAmounts: GetConfigParamsUseCase<CardAmounts>,
        private readonly savePurchasePersistence: SavePurchaseUseCase,
        private readonly getPurchasePersistence: GetPurchaseUseCase,
    ) {
        this.initCardAmounts();
    }

    public ngOnInit(): void {
        this.selectedCardAmount.emit(this.cardAmount);
        this.iniPurchasePersistence();
    }

    public iniPurchasePersistence(): void {
        this.getPurchasePersistence
            .execute()
            .subscribe((purchasePersistence) => {
                if (purchasePersistence?.amount) {
                    this.cardAmount = purchasePersistence.amount;
                }
            });
    }

    private initCardAmounts(): void {
        this.getCardAmounts.execute('cardAmounts').subscribe((data) => {
            this.amounts$ = data.default;
            this.min = data.min;
            this.max = data.max;
            this.step = data.step;
        });
    }

    public increaseAmount(amount: number) {
        if (this.cardAmount >= this.min && this.cardAmount < this.max) {
            this.cardAmount += amount;
            this.selectedCardAmount.emit(this.cardAmount);
        }
    }

    public decreaseAmount(amount: number) {
        if (this.cardAmount >= 501 && this.cardAmount <= this.max) {
            this.cardAmount -= amount;
            this.selectedCardAmount.emit(this.cardAmount);
        }
    }

    public setAmount(amount: number) {
        this.cardAmount = amount;
        this.selectedCardAmount.emit(this.cardAmount);
    }

    public nextButton() {
        this.newItemEvent.emit(this.cardAmount.toString());
        this.savePurchasePersistence
            .execute({ amount: this.cardAmount })
            .pipe(
                switchMap(() => {
                    return this.savePurchasePersistence.execute({
                        cardDesign: this.setCard!,
                    });
                }),
            )
            .subscribe();
    }
}
