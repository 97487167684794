import { ConfigurationsRepository } from '@/core/domain/configurations/repositories/configurarions.repository';
import { GetConfigParamsUseCase } from '../../core/domain/configurations/usecases/getConfigParams-loader.usecase';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfigurationImplRepository } from './configuration-impl.repository';

const cardDesignsLoaderUseCaseFactory = (
    configurationsRepository: ConfigurationsRepository,
) => new GetConfigParamsUseCase(configurationsRepository);

@NgModule({
    imports: [CommonModule],
    providers: [
        {
            provide: GetConfigParamsUseCase,
            useFactory: cardDesignsLoaderUseCaseFactory,
            deps: [ConfigurationsRepository],
        },
        {
            provide: ConfigurationsRepository,
            useClass: ConfigurationImplRepository,
        },
    ],
})
export class CardDesignsDataModule {}
