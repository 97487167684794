import { OrdersCompensationRepository } from '@/core/domain/orders-compensation/repositories/orders-compensation.repository';
import { GetOrdersCompensationParamsUseCase } from '@/core/domain/orders-compensation/usecases/getOrdersCompensation-loader.usecase';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OrdersCompensationImplRepository } from './orders-compensation-impl.repository';

const ordersCompensationLoaderUseCaseFactory = (
    ordersCompensationRepository: OrdersCompensationRepository,
) => new GetOrdersCompensationParamsUseCase(ordersCompensationRepository);

@NgModule({
    imports: [CommonModule],
    providers: [
        {
            provide: GetOrdersCompensationParamsUseCase,
            useFactory: ordersCompensationLoaderUseCaseFactory,
            deps: [OrdersCompensationRepository],
        },
        {
            provide: OrdersCompensationRepository,
            useClass: OrdersCompensationImplRepository,
        },
    ],
})
export class OrdersCompensationDataModule {}
