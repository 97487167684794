import { Component, Input } from '@angular/core';

type SpinnerSubType = 'grow' | 'border';
type SpinnerType = `spinner-${SpinnerSubType}`;

type SpinnerSize = `${SpinnerType}-sm`;

type SpinnerAlignDirection =
    | 'baseline'
    | 'top'
    | 'middle'
    | 'bottom'
    | 'text-bottom';
type SpinnerAlign = `align-${SpinnerAlignDirection}`;

@Component({
    selector: 'vg-spinner',
    templateUrl: './spinner.component.html',
})
export class SpinnerComponent {
    @Input() type: SpinnerType = 'spinner-border';
    @Input() size: SpinnerSize = 'spinner-border-sm';
    @Input() align: SpinnerAlign = 'align-middle';

    public get classNames() {
        return `${this.type} ${this.size} ${this.align}`.trim();
    }
}
