import { createAction, props } from '@ngrx/store';

export const incrementAction = createAction(
    '[Counter Component] Increment',
    props<{ value: number }>(),
);

export const decrementAction = createAction(
    '[Counter Component] Decrement',
    props<{ value: number }>(),
);

export const resetAction = createAction(
    '[Counter Component] Reset',
    props<{ value: number }>(),
);
