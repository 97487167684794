import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';

@Component({
    selector: 'vg-card-terms-conditions',
    templateUrl: './vg-card-terms-conditions.component.html',
    styleUrls: ['./vg-card-terms-conditions.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VgCardTermsConditionsComponent {
    @Input() enabledFinish = false;
    @Output() finish = new EventEmitter();
    @Input() loading = false;
    public executeImportantAction(): void {
        if (!this.enabledFinish) {
            return;
        }
        this.finish.emit();
    }
}
