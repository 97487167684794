import { getLanguage } from '../strings/path.utils';

export const singleTranslation = (value: any) => {
    if (typeof value === 'string') {
        return value;
    }
    return typeof value === 'object' && value !== null
        ? value[getLanguage()]
        : '';
};
