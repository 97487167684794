import { ModalErrorModel } from '@/core/domain/modal-error/models/modal-error.model';
import { Action, createReducer, on } from '@ngrx/store';
import * as actions from './modal-error.actions';

export const MODAL_ERROR_INITIAL_STATE: ModalErrorModel = {
    isOpen: false,
    error: '',
    description: '',
    redirectSSO: false,
};

export const _modalErrorReducer = createReducer(
    MODAL_ERROR_INITIAL_STATE,
    on(actions.openModalError, (_, { data }) => data),
    on(actions.closeModalError, () => MODAL_ERROR_INITIAL_STATE),
);

export const ModalErrorReducer = (
    state: ModalErrorModel | undefined,
    action: Action,
) => {
    return _modalErrorReducer(state, action);
};
