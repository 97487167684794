<vg-modal-wrapper [show]="show">
    <div
        class="modal fade show"
        id=""
        tabindex="-1"
        aria-modal="true"
        role="dialog"
        [ngClass]="show ? 'd-block' : 'd-none'"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header position-relative border-0">
                    <div class="modal-text-container">
                        <p
                            [innerHTML]="title"
                            class="fs-5 mb-0 fw-bold font-family-lato"
                        ></p>
                    </div>
                    <button
                        type="button"
                        class="position-absolute rounded-circle border-0 d-flex justify-content-center"
                        data-bs-dismiss="modal"
                        (click)="sendClose()"
                    >
                        <img [src]="'vg/images/close-black.svg' | cdn" alt="" />
                    </button>
                </div>
                <div
                    class="modal-body d-flex justify-content-center flex-column pt-0"
                >
                    <p [innerHTML]="text" class="lh-1 font-family-lato"></p>
                    <vg-button
                        [style]="'success-outline'"
                        class="w-100 d-grid"
                        (click)="sendClose()"
                    >
                        {{ btnTitle }}
                    </vg-button>
                </div>
            </div>
        </div>
    </div>
</vg-modal-wrapper>
