import { UseCase } from '@/core/base/use-case';
import { Observable } from 'rxjs';
import { AuthRepository } from '../repositories/auth.repository';

export class LogoutUseCase implements UseCase<void, void | null> {
    constructor(private readonly repository: AuthRepository) {}

    execute(params: void): Observable<void | null> {
        return this.repository.logout();
    }
}
