import { UseCase } from '@/core/base/use-case';
import { TranslationsRepository } from '../repositories/translations.repository';
import { Observable } from 'rxjs';

export class TranslationsTranslateRoutesUseCase
    implements UseCase<void, string>
{
    constructor(private readonly repository: TranslationsRepository) {}

    execute(): Observable<string> {
        return this.repository.translateRoutes();
    }
}
