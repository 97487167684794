<div class="terms-container">
    <div class="terms-content" ngbAccordion [closeOthers]="true">
        <div *ngFor="let tac of terms_conditions" ngbAccordionItem>
            <h5 ngbAccordionHeader>
                <button ngbAccordionButton>
                    {{ tac.title | translate }}
                </button>
            </h5>
            <div ngbAccordionCollapse>
                <div [innerHTML]="tac.info | translate" ngbAccordionBody></div>
            </div>
        </div>
    </div>
</div>
