import { getHttpHeaders } from '@/common/utils/http/headers.util';
import { AuthLoginInput } from '@/core/domain/auth/models/auth-login-input.data';
import { AuthApi } from '@/core/domain/auth/services/auth.api';
import { environment as env } from '@/env/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { LoginRequestBody } from './requests/login.request';
import { LoginExternalResponse } from './responses/login-external.response';
import { RestoreResponse } from './responses/restore.response';
import { RestoreRequestBody } from './requests/restore.request';

export class EzyService extends AuthApi {
    private readonly HOST = env.vivaApi.BASE_HOST;

    constructor(private readonly http: HttpClient) {
        super();
    }

    override login(input: AuthLoginInput): Observable<LoginExternalResponse> {
        const METHOD = env.vivaApi.ENDPOINTS.loginExternal;
        const URL = `${this.HOST}${METHOD}`;

        const headers = getHttpHeaders({
            'X-Api-Key': env.vivaApi.API_KEY,
            'X-Channel': env.channel,
        });

        const body = new LoginRequestBody(input);

        return this.http
            .post<LoginExternalResponse>(URL, body, { headers })
            .pipe(catchError(this.handleError));
    }

    override restore(authToken: string): Observable<RestoreResponse> {
        const METHOD = env.vivaApi.ENDPOINTS.restore;
        const URL = `${this.HOST}${METHOD}`;

        const headers = getHttpHeaders({
            Authorization: `Bearer ${authToken}`,
            'X-Api-Key': env.vivaApi.API_KEY,
            'X-Channel': env.channel,
        });

        const body = new RestoreRequestBody();

        return this.http
            .post<RestoreResponse>(URL, body, { headers })
            .pipe(catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
            // A client-side or network error occurred. Handle it accordingly.
            const GENERIC_ERROR = {
                type: 'ERROR',
                action: 'CANCEL',
                code: 'UNKNOWN_AUTH_ERROR',
                message: 'Something went wrong',
                diagnosticInformation: null,
                exceptionId: null,
                parameters: [],
            };

            return throwError(() => GENERIC_ERROR);
        }

        return throwError(() => error);
    }
}
