import { createReducer, on } from '@ngrx/store';
import { updateRefreshData } from './refresh-data.actions';

export const updateRefreshDataReducer = (
    _: boolean,
    { value }: { value: boolean },
): boolean => value;

export const RefreshDataReducer = createReducer(
    false,
    on(updateRefreshData, updateRefreshDataReducer),
);
