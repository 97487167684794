import { DateTime, Duration } from 'luxon';

export const getTimeStampPlusExpiration = (exp: string) => {
    const current = DateTime.now().toUTC();

    const [days, time] = exp.split('.');
    const [hours, minutes, seconds] = time.split(':').map(Number);

    const duration = Duration.fromObject({
        days: Number(days),
        hours,
        minutes,
        seconds,
    });

    return current.plus(duration).toMillis();
};

export const getTimeStampPlusExpirationBySeconds = (seconds: string) => {
    const current = DateTime.now().toUTC();
    const duration = Duration.fromObject({ seconds: Number(seconds) });
    return current.plus(duration).toMillis();
};

export const isTimestampOnTime = (timestamp: number) => {
    const current = DateTime.now().toUTC();
    return timestamp - current.toMillis() > 0;
};
