import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    Self,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MatDateFormats,
} from '@angular/material/core';
import {
    MatCalendar,
    MatDatepicker,
    yearsPerPage,
} from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { Subject, noop, takeUntil } from 'rxjs';
import { CardSchema } from '../../../../core/domain/recurly/models/CardSchema';
import { GetCardSchemaUseCase } from '../../../../core/domain/recurly/usecases/recurly-get-card-schema.usecase';
import { CdnService } from '@/common/cdn/cdn.service';

@Component({
    selector: 'vg-input',
    templateUrl: './vg-input.component.html',
    styleUrls: ['./vg-input.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VgInputComponent implements ControlValueAccessor, OnInit {
    @Input() flowPage = 'core_errors';
    @Input() id: string | null = null;
    @Input() icon: string | null = null;
    @Input() iconInactive: string | null = null;
    @Input() label: string | null = null;
    @Input() type:
        | 'text'
        | 'email'
        | 'tel'
        | 'password'
        | 'card'
        | 'expirationDate' = 'text';
    @Input() placeholder = '';
    @Input() mask: string | null = null;
    @Input() isTextarea = false;
    @Input() isSelect = false;
    @Input() isDatepicker = false;
    @Input() disabled = false;
    @Input() min: unknown = null;
    @Input() max: unknown = null;
    @Input() autocomplete: string | null = null;
    @Input() maxlength: string | null = null;
    @Input() inputmode = 'text';
    @Input() autocorrect = null;
    @Input() readonly = null;
    @Input() formControlName: string | number | null = null;
    @Output() cardSchema = new EventEmitter<CardSchema>();
    @Output() date = new EventEmitter<Date | null>();

    public uniqClassSelect = 'select-' + Math.random().toString(36).slice(2);

    ngOnInit() {
        if (this.iconInactive === null && this.icon !== null) {
            this.iconInactive = this.icon;
        }
    }

    //The internal data model
    private innerValue: unknown = null;

    //Placeholders for the callbacks which are later provided
    //by the Control Value Accessor
    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: unknown) => void = noop;

    public cardTypeImage = '';

    @Input() items: { id: number | string; name: string }[] = [];

    public statusDatepicker = '';

    @ViewChild('datepicker') datepicker: MatDatepicker<Date> | null = null;
    @ViewChild('inputDatePicker') inputDatePicker: HTMLInputElement | null =
        null;

    public customHeaderComponent = CustomHeaderComponent;

    constructor(
        @Self() @Optional() public ngControl: NgControl,
        private readonly getCardSchemaUseCase: GetCardSchemaUseCase,
    ) {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    protected elementInViewport(el: any) {
        let top = el.offsetTop;
        let left = el.offsetLeft;
        const width = el.offsetWidth;
        const height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        return (
            top >= window.scrollY &&
            left >= window.scrollX &&
            top + height <= window.scrollY + window.innerHeight &&
            left + width <= window.scrollX + window.innerWidth
        );
    }

    public onClickDatepickerToggle(): void {
        this.ngControl.control?.markAsTouched();
    }

    public onClosedDatepicker(): void {
        this.statusDatepicker = 'closed';
    }

    public onOpenedDatepicker(): void {
        this.statusDatepicker = 'opened';
    }

    public onFocusInputDatepicker(): void {
        this.statusDatepicker = 'toOpen';
        this.datepicker?.open();
    }

    public onDateChange(event: any) {
        this.date.emit(event.value);
    }

    public onInput(event: unknown): void {
        if (this.innerValue === null) {
            return;
        }
        if ((this.innerValue as string)?.length >= 6 && this.type === 'card') {
            this.findCardSchemna(this.innerValue as unknown as string);
        }
        if (this.type === 'expirationDate' && this.ngControl.control?.valid) {
            this.validateMonth();
        }
    }

    protected validateMonth(): void {
        const month = (this.innerValue as string).substring(0, 2);
        if (parseInt(month) > 12) {
            this.ngControl.control?.setErrors({ invalidDate: true });
        }
    }

    private findCardSchemna(bin: string): void {
        this.getCardSchemaUseCase
            .execute({ bin: bin })
            .subscribe((response) => this.onGetCardSchemaUseCase(response));
    }

    private onGetCardSchemaUseCase(cardSchema: CardSchema): void {
        this.cardTypeImage = cardSchema.schemeUrl ?? '';
        if (cardSchema.scheme.length === 0) {
            this.ngControl.control?.setErrors({ invalidCardNumber: true });
        }
        this.cardSchema.emit(cardSchema);
    }

    public onBlurSelect(): void {
        this.ngControl.control?.markAsTouched();
    }

    public getDynamiClass(): string {
        if (this.statusDatepicker === 'opened') {
            return 'opened';
        }
        return (
            (this.icon !== null ? 'has-icon' : '') +
            ' ' +
            (this.getControlErrors().length > 0 &&
            this.ngControl?.control?.touched === true
                ? 'is-invalid'
                : '') +
            ' ' +
            (this.getControlErrors().length === 0 &&
            this.ngControl?.control?.touched === true
                ? this.type === 'card'
                    ? 'is-valid-card'
                    : 'is-valid'
                : this.value !== '' && this.getControlErrors().length === 0
                  ? this.type === 'card'
                      ? 'is-valid-card'
                      : 'is-valid'
                  : '') +
            ' ' +
            this.statusDatepicker
        );
    }

    public getDynamicTextAreaClass(): string {
        return typeof this.value === 'string' && this.value.length > 0
            ? 'is-valid'
            : '';
    }

    //get accessor
    public get value(): unknown {
        return this.innerValue;
    }

    //set accessor including call the onchange callback
    public set value(v: unknown) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }

    //Set touched on blur
    public onBlur() {
        this.onTouchedCallback();
    }

    //From ControlValueAccessor interface
    public writeValue(value: unknown) {
        if (value !== this.innerValue) {
            this.innerValue =
                typeof value === 'string' && value.length === 0 ? null : value;
        }
    }

    //From ControlValueAccessor interface
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    //From ControlValueAccessor interface
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    public getControlErrors(): { error: string; reason: unknown }[] {
        if (!this.ngControl?.control?.errors) {
            return [];
        }
        const errors: { error: string; reason: unknown }[] = [];
        Object.keys(this.ngControl.control.errors).forEach((key) => {
            errors.push({
                error: key,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                reason: this.ngControl.control!.errors![key],
            });
        });
        return errors;
    }

    public getClass() {
        return 'x';
    }
}

@Component({
    selector: 'vg-custom-header-calendar',
    styles: [
        `
            .header {
                display: flex;
                align-items: center;
                padding: 0;
                margin: 18px 18px 10px 18px;
                border-bottom: 1px solid #e5e5e5;
            }
            .header-label {
                flex: 1;
                font-size: 14.76px;
                font-weight: 700;
                text-align: left;
                line-height: 24.29px;
                font-family: 'Axiforma';
                margin-bottom: 7px;
            }
            .btn-arrows {
                width: 14px;
                margin-bottom: 7px;
                cursor: pointer;
            }
            .btn-arrows.left {
                margin-right: 12px;
            }
            .btn-arrows.right.disabled {
                transform: rotate(180deg);
            }
        `,
    ],
    template: `<div class="header">
        <span class="header-label">{{ periodLabel }}</span>
        <img
            (keyup)="previousClicked('month')"
            [attr.aria-hidden]="true"
            (click)="previousClicked('month')"
            class="btn-arrows left"
            [src]="
                getArrowCdn(
                    !previousEnabled(),
                    'arrow-left-gray',
                    'arrow-left-black'
                )
            "
            alt=""
        />
        <img
            (keyup)="previousClicked('month')"
            [attr.aria-hidden]="true"
            [ngClass]="{ disabled: !nextEnabled() }"
            (click)="nextClicked('month')"
            class="btn-arrows right"
            [src]="
                getArrowCdn(
                    !nextEnabled(),
                    'arrow-left-gray',
                    'arrow-right-black'
                )
            "
            alt=""
        />
    </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatButtonModule, MatIconModule, CommonModule],
})
export class CustomHeaderComponent<D> implements OnDestroy {
    private _destroyed = new Subject<void>();

    public getArrowCdn(status: boolean, left: string, right: string): string {
        return CdnService.url('vg/images/' + (status ? left : right) + '.svg');
    }

    constructor(
        private _calendar: MatCalendar<D>,
        private _dateAdapter: DateAdapter<D>,
        @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
        cdr: ChangeDetectorRef,
    ) {
        _calendar.stateChanges
            .pipe(takeUntil(this._destroyed))
            .subscribe(() => cdr.markForCheck());
    }

    ngOnDestroy() {
        this._destroyed.next();
        this._destroyed.complete();
    }

    nextEnabled(): boolean {
        return (
            !this._calendar.maxDate ||
            !this._isSameView(this._calendar.activeDate, this._calendar.maxDate)
        );
    }

    previousEnabled(): boolean {
        if (!this._calendar.minDate) {
            return true;
        }
        return (
            !this._calendar.minDate ||
            !this._isSameView(this._calendar.activeDate, this._calendar.minDate)
        );
    }

    private _isSameView(date1: D, date2: D): boolean {
        if (this._calendar.currentView == 'month') {
            return (
                this._dateAdapter.getYear(date1) ===
                    this._dateAdapter.getYear(date2) &&
                this._dateAdapter.getMonth(date1) ===
                    this._dateAdapter.getMonth(date2)
            );
        }
        if (this._calendar.currentView == 'year') {
            return (
                this._dateAdapter.getYear(date1) ==
                this._dateAdapter.getYear(date2)
            );
        }
        // Otherwise we are in 'multi-year' view.
        return this.isSameMultiYearView(
            this._dateAdapter,
            date1,
            date2,
            this._calendar.minDate,
            this._calendar.maxDate,
        );
    }

    public isSameMultiYearView(
        dateAdapter: DateAdapter<D>,
        date1: D,
        date2: D,
        minDate: D | null,
        maxDate: D | null,
    ): boolean {
        const year1 = dateAdapter.getYear(date1);
        const year2 = dateAdapter.getYear(date2);
        const startingYear = this.getStartingYear(
            dateAdapter,
            minDate,
            maxDate,
        );
        return (
            Math.floor((year1 - startingYear) / yearsPerPage) ===
            Math.floor((year2 - startingYear) / yearsPerPage)
        );
    }

    public getStartingYear(
        dateAdapter: DateAdapter<D>,
        minDate: D | null,
        maxDate: D | null,
    ): number {
        let startingYear = 0;
        if (maxDate) {
            const maxYear = dateAdapter.getYear(maxDate);
            startingYear = maxYear - yearsPerPage + 1;
        } else if (minDate) {
            startingYear = dateAdapter.getYear(minDate);
        }
        return startingYear;
    }

    get periodLabel() {
        return this._dateAdapter
            .format(
                this._calendar.activeDate,
                this._dateFormats.display.monthYearLabel,
            )
            .toLocaleUpperCase();
    }

    previousClicked(mode: 'month' | 'year') {
        this._calendar.activeDate =
            mode === 'month'
                ? this._dateAdapter.addCalendarMonths(
                      this._calendar.activeDate,
                      -1,
                  )
                : this._dateAdapter.addCalendarYears(
                      this._calendar.activeDate,
                      -1,
                  );
    }

    nextClicked(mode: 'month' | 'year') {
        this._calendar.activeDate =
            mode === 'month'
                ? this._dateAdapter.addCalendarMonths(
                      this._calendar.activeDate,
                      1,
                  )
                : this._dateAdapter.addCalendarYears(
                      this._calendar.activeDate,
                      1,
                  );
    }
}
