<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div class="vg-swiper-landing-home">
    <div class="swiper-button-prev-outside" (click)="onClickPrev()">
        <img
            [src]="'vg/images/arrow_green.svg' | cdn"
            alt="arrow_green"
            class="card-redeem-img"
        />
    </div>
    <vg-swiper
        *ngIf="showSwiper"
        [loop]="true"
        [centeredSlides]="true"
        [slidesPerView]="slidesPerView"
        [spaceBetween]="spaceBetween"
        [swiperButtonNext]="'.swiper-button-next-outside'"
        [swiperButtonPrev]="'.swiper-button-prev-outside'"
        [showPagination]="false"
        [showBtnNavigation]="false"
    >
        <div
            class="swiper-slide"
            *ngFor="let card of cards$ | async; trackBy: identify"
        >
            <vg-card (click)="onClickCard(card)">
                <img
                    [src]="card.resource"
                    [alt]="card.alternateText | singleTranslation"
                />
                <h3 class="title-swiper">
                    {{ card.alternateText | singleTranslation }}
                </h3>
            </vg-card>
        </div>
    </vg-swiper>
    <div class="swiper-button-next-outside" (click)="onClickNext()">
        <img
            [src]="'vg/images/arrow_green.svg' | cdn"
            alt="arrow_green"
            class="card-redeem-img"
        />
    </div>
</div>
