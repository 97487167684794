import {
    Component,
    ElementRef,
    HostListener,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';

export type BTN_STYLE =
    | 'primary'
    | 'primary-outline'
    | 'secondary'
    | 'tertiary'
    | 'success'
    | 'success-outline'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'light-outline'
    | 'dark'
    | 'dark-outline'
    | 'link'
    | 'link-light';

@Component({
    selector: 'vg-button',
    templateUrl: './vg-button.component.html',
    styleUrls: ['./vg-button.component.scss'],
})
export class VgButtonComponent implements OnChanges {
    public styleMap = {
        primary: 'btn-primary',
        'primary-outline': 'btn-outline-primary',
        secondary: 'btn-secondary',
        tertiary: 'btn-tertiary',
        success: 'btn-success',
        'success-outline': 'btn-outline-success',
        danger: 'btn-danger',
        warning: 'btn-warning',
        info: 'btn-info',
        light: 'btn-light',
        'light-outline': 'btn-outline-light',
        dark: 'btn-dark',
        'dark-outline': 'btn-outline-dark',
        link: 'btn-link',
        'link-light': 'btn-link light',
    };
    @Input() style: BTN_STYLE = 'primary';
    @Input() type = 'button';
    @Input() icon: string | null = null;
    @Input() iconPosition: 'left' | 'right' = 'left';
    @Input() disabled = false;
    @Input() onlyIcon = false;
    @Input() underline = false;
    @Input() loading = false;
    @Input() href: string | null = null;
    @Input() arrowAnimate = false;
    @Input() data = {};
    @HostListener('click', ['$event']) onClick() {
        if (this.href != null) {
            this.router.navigateByUrl(this.href, { state: this.data });
        }
    }
    constructor(
        private readonly router: Router,
        private elementRef: ElementRef,
    ) {}
    ngOnChanges(changes: SimpleChanges): void {
        if (this.disabled) {
            this.elementRef.nativeElement.classList.add('disabled');
        } else {
            this.elementRef.nativeElement.classList.remove('disabled');
        }
        if (this.loading) {
            this.elementRef.nativeElement.classList.add('loading');
        } else {
            this.elementRef.nativeElement.classList.remove('loading');
        }
    }
}
