import { getBasicAuthorization } from '@/common/utils/http/basic-auth.util';
import { getHttpHeaders } from '@/common/utils/http/headers.util';
import { SingleSignOnApi } from '@/core/domain/auth/services/sso.api';
import { environment as env } from '@/env/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { LoginExternalResponse } from '../ezy/responses/login-external.response';
import { UserInfoSuccessResponse } from './responses/get-user-info.response';
import { RefreshTokenResponse } from './responses/refresh-token.response';

export class SingleSignOnService extends SingleSignOnApi {
    private readonly HOST = env.SSO.BASE_HOST;

    constructor(private readonly http: HttpClient) {
        super();
    }

    override loginExternal(token: string): Observable<LoginExternalResponse> {
        const METHOD = env.SSO.ENDPOINTS.loginExternal;
        const URL = `${this.HOST}${METHOD}`;
        const headers = getHttpHeaders({
            Authorization: `Basic ${getBasicAuthorization()}`,
            'X-Channel': env.channel,
        });

        return this.http
            .post<LoginExternalResponse>(
                URL,
                {
                    providerType: env.providerType,
                    token: token,
                },
                { headers },
            )
            .pipe(catchError(this.handleError));
    }

    override userInfo(
        accessToken: string,
    ): Observable<UserInfoSuccessResponse> {
        const METHOD = env.SSO.ENDPOINTS.getUserInfo;
        const URL = `${this.HOST}${METHOD}`;

        const headers = getHttpHeaders({
            Authorization: `Bearer ${accessToken}`,
            'X-Channel': env.channel,
        });

        return this.http
            .get<UserInfoSuccessResponse>(URL, { headers })
            .pipe(catchError(this.handleError));
    }

    override refresh(refreshToken: string): Observable<RefreshTokenResponse> {
        const METHOD = env.SSO.ENDPOINTS.refreshToken;
        const URL = `${this.HOST}${METHOD}`;

        const headers = getHttpHeaders({
            Authorization: `Basic ${getBasicAuthorization()}`,
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Channel': env.channel,
        });

        const body = new URLSearchParams();
        body.set('refresh_token', refreshToken);
        body.set('grant_type', 'refresh_token');

        return this.http
            .post<RefreshTokenResponse>(URL, body.toString(), { headers })
            .pipe(catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
            // A client-side or network error occurred. Handle it accordingly.
            const GENERIC_ERROR = {
                type: 'ERROR',
                action: 'CANCEL',
                code: 'UNKNOWN_AUTH_ERROR',
                message: 'Something went wrong',
                diagnosticInformation: null,
                exceptionId: null,
                parameters: [],
            };

            return throwError(() => GENERIC_ERROR);
        }

        return throwError(() => error);
    }
}
