<vg-card>
    <div class="contenedor-card-details px-md-3 pt-md-2">
        <div class="row">
            <div class="col-5 col-md-4">
                <span class="text-desc">Destinatario</span>
                <p class="text-data font-family-lato no-wrap">
                    {{ receiverName }}
                </p>
            </div>
            <div class="col-7 col-md-4">
                <span class="text-desc">Correo destinatario</span>
                <p class="text-data font-family-lato no-wrap">
                    {{ receiverEmail }}
                </p>
            </div>
            <div class="col-5 col-md-4">
                <span class="text-desc">Fecha de envío</span>
                <p class="text-data font-family-lato">{{ receiverDate }}</p>
            </div>
            <div class="col-7 col-md-4">
                <span class="text-desc">Fecha de vencimiento</span>
                <p class="text-data font-family-lato">{{ expirationDate }}</p>
            </div>
            <div class="col-12 col-md-8 mb-3">
                <span class="text-desc">Estatus</span>
                <div class="box-badge">
                    <vg-badge [style]="'warning'">{{ status }}</vg-badge>
                </div>
            </div>
            <div class="col-12 mt-2 mt-md-3">
                <p class="text-data font-family-lato">{{ instructions }}</p>
            </div>
        </div>
        <hr />
        <div class="mt-30">
            <h3 class="title-h3">Datos de compra</h3>
            <p class="title-p">Viva Gift - Tarjeta de regalo</p>
            <div class="row">
                <div class="col-6 col-md-4">
                    <span class="text-desc">Monto</span>
                    <p class="text-data font-family-lato">{{ amount }}</p>
                </div>
                <div class="col-6 col-md-4">
                    <span class="text-desc">Orden</span>
                    <p class="text-data font-family-lato">{{ order }}</p>
                </div>
                <div class="col-6 col-md-4">
                    <span class="text-desc">Código para canjear</span>
                    <p class="text-data font-family-lato">{{ giftcardCode }}</p>
                </div>
                <div class="col-6">
                    <span class="text-desc">Fecha de vencimiento</span>
                    <p class="text-data font-family-lato">
                        {{ expirationDate }}
                    </p>
                </div>
            </div>
            <p class="title-p mt-3">Forma de pago</p>
            <div class="row">
                <div class="col-12 col-md-4">
                    <span class="text-desc">Tarjeta de crédito</span>
                    <p class="text-data font-family-lato">
                        {{ cardTermination }}
                    </p>
                </div>
                <div class="col-12 col-md-8">
                    <span class="text-desc">Información de tarjeta</span>
                    <p class="text-data font-family-lato">
                        {{ cardInformation }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</vg-card>
