import { PurchasePersistenceModel } from '@/core/domain/purchase-persistence/models/PurchasePersistence.model';
import { GetPurchaseUseCase } from '@/core/domain/purchase-persistence/usecases/get-purchase-persistence.usecase';
import { selectAuthValue } from '@/data/auth/store/auth.selectors';
import { AuthUserData } from '@/data/auth/store/models/auth.store';
import { Component, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { first, of, switchMap } from 'rxjs';
import { ANIMATIONS_CARD } from '../card-amount/card-amount.component';

@Component({
    selector: 'vg-card-personal-information',
    templateUrl: './vg-card-personal-information.component.html',
    styleUrls: ['./vg-card-personal-information.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: ANIMATIONS_CARD,
})
export class VgCardPersonalInformationComponent {
    public purchasePersistence: PurchasePersistenceModel | null = null;
    public auth: AuthUserData | null = null;
    constructor(
        private readonly getPurchasePersistence: GetPurchaseUseCase,
        private readonly store: Store,
    ) {
        this.initPurchasePersistence();
        this.initAuth();
    }
    public initAuth(): void {
        this.store
            .select(selectAuthValue)
            .pipe(
                first(),
                switchMap((auth) => {
                    this.auth = auth;
                    return of(auth);
                }),
            )
            .subscribe();
    }
    public initPurchasePersistence(): void {
        this.getPurchasePersistence
            .execute()
            .subscribe((purchasePersistence) => {
                this.purchasePersistence = purchasePersistence;
            });
    }
}
