import { UseCase } from '@/core/base/use-case';
import { Observable } from 'rxjs';
import { CardRedeem } from '../models/card-redemm.model';
import { CardRedeemRepository } from '../repositories/card-redeem.repository';

export type LoderCardRedeem = CardRedeem;

export class PostCardRedeemUseCase implements UseCase<LoderCardRedeem, any> {
    constructor(private readonly repository: CardRedeemRepository) {}

    execute(param: LoderCardRedeem): Observable<any> {
        return this.repository.postCardRedeem(param);
    }
}

export class PostCardRedeemB2BUseCase implements UseCase<LoderCardRedeem, any> {
    constructor(private readonly repository: CardRedeemRepository) {}

    execute(param: LoderCardRedeem): Observable<any> {
        return this.repository.postCardRedeemB2B(param);
    }
}

export class PostCardRedeemMPUseCase implements UseCase<LoderCardRedeem, any> {
    constructor(private readonly repository: CardRedeemRepository) {}

    execute(param: LoderCardRedeem): Observable<any> {
        return this.repository.postCardRedeemMP(param);
    }
}
