import { UseCase } from '@/core/base/use-case';
import { Observable } from 'rxjs';
import { PurchasePersistenceModel } from '../models/PurchasePersistence.model';
import { PurchasePersistenceRepository } from '../repositories/purchase-persistence.repository';

export class GetPurchaseUseCase
    implements UseCase<never, PurchasePersistenceModel>
{
    constructor(private readonly repository: PurchasePersistenceRepository) {}

    execute(): Observable<PurchasePersistenceModel> {
        return this.repository.getPurchase();
    }
}
