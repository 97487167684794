<vg-card class="vg-card-giftcard">
    <div class="container-card-price">
        <img
            class="card-img"
            *ngIf="img !== null && img !== undefined && img.length > 0"
            [src]="img"
            alt=""
        />
        <div
            class="card-img"
            *ngIf="img === null || img === undefined || img === ''"
        ></div>
        <div *ngIf="price !== null" class="container-price">
            <div class="currency d-none d-sm-block">MXN</div>
            <div class="amount">{{ price | formatNumber }}</div>
        </div>
    </div>
    <ng-content></ng-content>
</vg-card>
