import { UseCase } from '@/core/base/use-case';
import { Observable } from 'rxjs';
import { PurchasePersistenceModel } from '../models/PurchasePersistence.model';
import { PurchasePersistenceRepository } from '../repositories/purchase-persistence.repository';

export class SavePurchaseUseCase
    implements UseCase<PurchasePersistenceModel, PurchasePersistenceModel>
{
    constructor(private readonly repository: PurchasePersistenceRepository) {}

    execute(
        params: PurchasePersistenceModel,
    ): Observable<PurchasePersistenceModel> {
        return this.repository.savePurchase(params);
    }
}
