import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

@Directive({
    selector: '[vgEnterTheViewportNotifier]',
})
export class EnterTheViewportNotifierDirective implements AfterViewInit {
    @Output() public inViewport: EventEmitter<any> = new EventEmitter();
    @Input() public inViewportOptions = '{}';
    private _intersectionObserver?: IntersectionObserver = undefined;

    constructor(private _element: ElementRef) {}

    public ngAfterViewInit() {
        this._intersectionObserver = new IntersectionObserver(
            (entries) => {
                this.checkForIntersection(entries);
            },
            this.inViewportOptions ? JSON.parse(this.inViewportOptions) : {},
        );
        this._intersectionObserver.observe(
            this._element.nativeElement as Element,
        );
    }

    private checkForIntersection = (
        entries: Array<IntersectionObserverEntry>,
    ) => {
        entries.forEach((entry: IntersectionObserverEntry) => {
            if (this.checkIfIntersecting(entry)) {
                this.inViewport.emit({ target: this._element });
                this._intersectionObserver?.unobserve(
                    this._element.nativeElement as Element,
                );
                this._intersectionObserver?.disconnect();
            }
        });
    };

    private checkIfIntersecting(entry: IntersectionObserverEntry) {
        return (
            (entry as any).isIntersecting &&
            entry.target === this._element.nativeElement
        );
    }
}
