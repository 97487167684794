<vg-modal-wrapper [show]="show">
    <div
        class="modal fade show"
        id=""
        tabindex="-1"
        aria-modal="true"
        role="dialog"
        [ngClass]="show ? 'd-block' : 'd-none'"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div
                style="background-color: transparent; border: 0"
                class="modal-content"
            >
                <ng-lottie
                    [options]="{
                        path: 'vg/lotties/loader-viva-gift.json' | cdn
                    }"
                />
            </div>
        </div>
    </div>
</vg-modal-wrapper>
