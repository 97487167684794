<div class="card-faq" ngbAccordion [closeOthers]="true">
    <div *ngFor="let faq of faqs" ngbAccordionItem>
        <h5 ngbAccordionHeader>
            <button
                [vgOnClickSendTag]="{
                    event: 'ev_viva_gift_lp',
                    category: 'viva_gift_faqs',
                    action: faq.title | translate
                }"
                ngbAccordionButton
            >
                {{ faq.title | translate }}
            </button>
        </h5>
        <div ngbAccordionCollapse>
            <div [innerHTML]="faq.info | translate" ngbAccordionBody></div>
        </div>
    </div>
</div>
