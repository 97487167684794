import { singleTranslation } from '@/common/utils/translations/singleTranslation.utils';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'singleTranslation',
})
export class SingleTranslationPipe implements PipeTransform {
    transform(value: any): string {
        return singleTranslation(value);
    }
}
