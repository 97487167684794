import { GtmRepository } from '@/core/domain/gtm/repositories/gtm.repository';
import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class GtmImplRepository extends GtmRepository {
    constructor(private gtmService: GoogleTagManagerService) {
        super();
    }

    override setDataLayer<T extends object>(dataLayer: T): Observable<void> {
        this.gtmService.pushTag(dataLayer).catch(() => {
            //TODO: add gtm errors
        });
        return of();
    }
}
