import { UrlService } from '@/common/url/url.service';
import { CardDesign } from '@/core/domain/configurations/models/configurations.model';
import { GtmLandingSlider } from '@/core/domain/gtm/models/gtm-landig-slider.models';
import { SetDataLayerParamsUseCase } from '@/core/domain/gtm/usecases/setDataLayer.usecase';
import { DeletePurchaseUseCase } from '@/core/domain/purchase-persistence/usecases/delete-purchase-persistence.usecase ';
import { SavePurchaseUseCase } from '@/core/domain/purchase-persistence/usecases/save-purchase-persistence.usecase';
import {
    Component,
    HostListener,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, switchMap } from 'rxjs';
import { GetConfigParamsUseCase } from '../../../../core/domain/configurations/usecases/getConfigParams-loader.usecase';

@Component({
    selector: 'vg-swiper-landing-home',
    templateUrl: './swiper-landing-home.component.html',
    styleUrls: ['./swiper-landing-home.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SwiperLandingHomeComponent implements OnInit {
    cards$ = new BehaviorSubject<CardDesign[]>([]);
    showSwiper = false;

    constructor(
        private readonly getCardDesigns: GetConfigParamsUseCase<CardDesign[]>,
        private readonly savePurchasePersistence: SavePurchaseUseCase,
        private readonly deletePurchasePersistence: DeletePurchaseUseCase,
        private readonly gtmDataLayer: SetDataLayerParamsUseCase,
        private readonly router: Router,
        private readonly url: UrlService,
        private readonly translate: TranslateService,
    ) {
        this.initCardDesigns();
    }

    private initCardDesigns(): void {
        this.getCardDesigns.execute('cardDesigns').subscribe((data) => {
            this.cards$.next(data);
            this.showSwiper = true;
        });
    }

    public identify(index: number, item: any) {
        return item.id;
    }

    slidesPerView!: number;
    spaceBetween!: string;

    ngOnInit(): void {
        this.getScreenSize(window.innerWidth);
    }

    public onClickCard(card: CardDesign): void {
        this.tagClickSlider(
            this.translate.instant('vg_tag_action_select_giftcard_' + card.id),
        );
        this.deletePurchasePersistence.execute().subscribe();
        this.savePurchasePersistence
            .execute({
                cardDesign: card,
            })
            .pipe(switchMap(() => this.url.format('purchase/amount')))
            .subscribe((url) => this.router.navigateByUrl(url));
    }

    private tagClickSlider(action: string): void {
        this.gtmDataLayer.execute<GtmLandingSlider>({
            event: 'ev_viva_gift_lp',
            category: 'viva_gift_slider',
            action: action,
        });
    }

    public onClickNext(): void {
        this.tagClickSlider('click_arrow_right');
    }
    public onClickPrev(): void {
        this.tagClickSlider('click_arrow_left');
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: Event) {
        const windowWidth = (event.target as Window).innerWidth;
        this.getScreenSize(windowWidth);
    }

    getScreenSize(size: number) {
        if (size <= 769) {
            this.slidesPerView = 1.4;
            this.spaceBetween = '15px';
        } else {
            this.slidesPerView = 3;
            this.spaceBetween = '40px';
        }
    }
}
