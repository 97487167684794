import { TranslationsGetLanguageUseCase } from '@/core/domain/translation/usecases/translations-get-language.usecase';
import { Injectable } from '@angular/core';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { Observable, Observer, forkJoin, of, switchMap, take } from 'rxjs';
import { getLanguage } from '../utils/strings/path.utils';
import { environment } from '@/env/environment';

@Injectable({
    providedIn: 'root',
})
export class UrlService {
    constructor(
        private readonly getLanguageUseCase: TranslationsGetLanguageUseCase,
        private translate: TranslateService,
    ) {}

    public format(
        resource: string,
        toLang: string | null = null,
    ): Observable<string> {
        return this.translateUrl(
            resource.replace(/^\/+/, ''),
            toLang || getLanguage(),
        ).pipe(
            switchMap((translateUrl) => {
                return forkJoin([
                    of(translateUrl),
                    this.getLanguageUseCase.execute(),
                ]);
            }),
            switchMap(([response, currentLang]) => {
                if (!environment.production) {
                    return of(
                        ['', currentLang.language, 'vivagift', response].join(
                            '/',
                        ),
                    );
                }
                return of(['', response].join('/'));
            }),
            switchMap((url) => of(url.replace(/\/$/, ''))),
        );
    }

    //TODO: mover este método a utils
    protected get(obj: any, path: string, defaultValue: any) {
        // Dividimos el camino en partes usando el separador '.'
        const pathArray = path.split('.');

        // Iteramos sobre cada parte del camino para acceder a las propiedades anidadas del objeto
        for (let i = 0; i < pathArray.length; i++) {
            // Verificamos si el objeto actual es nulo o indefinido, en cuyo caso retornamos el valor predeterminado
            if (!obj || typeof obj !== 'object') return defaultValue;

            // Accedemos a la propiedad actual del objeto
            obj = obj[pathArray[i]];
        }

        // Si hemos llegado hasta aquí, retornamos el valor obtenido o el valor predeterminado si es nulo o indefinido
        return obj !== undefined ? obj : defaultValue;
    }

    protected translateUrl(subPaths: string, lang: string): Observable<string> {
        const subPathsItems = subPaths.split('/');
        return new Observable((observer: Observer<string>) => {
            const fastNewFullPaths: string[] = [];
            of(this.get(this.translate.translations, lang, null)).subscribe(
                (translations) => {
                    subPathsItems.forEach((subPath: string) => {
                        fastNewFullPaths.push(
                            this.get(
                                translations,
                                'routes.' + subPath,
                                subPath,
                            ),
                        );
                    });
                    observer.next(fastNewFullPaths.join('/'));
                },
            );
            const slowNewFullPaths: string[] = [];
            this.translate.onTranslationChange
                .pipe(
                    take(1),
                    switchMap((event: TranslationChangeEvent) => {
                        subPathsItems.forEach((subPath: string) => {
                            slowNewFullPaths.push(
                                this.get(
                                    event.translations,
                                    'routes.' + subPath,
                                    subPath,
                                ),
                            );
                        });
                        return of(slowNewFullPaths.join('/'));
                    }),
                )
                .subscribe((url) => {
                    observer.next(url);
                    observer.complete();
                });
        });
    }
}
