import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Output,
} from '@angular/core';

const IGNORE_ELEMENT_OUTSIDE = 'ignore-element-outside';

@Directive({
    selector: '[vgClickOutside]',
})
export class ClickOutsideDirective {
    @Output() vgClickOutside: EventEmitter<void> = new EventEmitter();

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement: any) {
        const allElementsIgnore = document.querySelectorAll(
            '.' + IGNORE_ELEMENT_OUTSIDE,
        );
        let allElementsIgnoreCount = 0;
        allElementsIgnore.forEach((element) => {
            if (element.contains(targetElement)) {
                allElementsIgnoreCount++;
            }
        });
        setTimeout(() => {
            if (
                !this.elementRef.nativeElement.contains(targetElement) &&
                !targetElement.classList.contains(IGNORE_ELEMENT_OUTSIDE) &&
                allElementsIgnoreCount == 0
            ) {
                this.vgClickOutside.emit();
            }
        });
    }

    constructor(private elementRef: ElementRef) {}
}
