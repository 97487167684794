import { Mapper } from '@/core/base/mapper';
import { SingleSignOnLoginInput } from '@/core/domain/auth/models/auth-sso-login-input.data';
import { AuthData } from '@/core/domain/auth/models/auth.data';

export class SingleSignOnMapper
    implements Mapper<AuthData, SingleSignOnLoginInput>
{
    mapFrom(param: AuthData): SingleSignOnLoginInput {
        return {
            accessToken: param.access_token,
            expiresIn: param.expires_in,
            idToken: param.id_token,
            refreshToken: param.refresh_token,
            scope: param.scope,
            tokenType: param.token_type,
            state: param.state,
        };
    }

    /**
     * @deprecated
     */
    mapTo(param: SingleSignOnLoginInput): AuthData {
        throw new Error('Method not implemented.');
    }
}
