import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RecurlyRepository } from '../../core/domain/recurly/repositories/recurly.repository';
import { RecurlyImplRepository } from './recurly-impl.repository';
import { CreateTokenCardUseCase } from '../../core/domain/recurly/usecases/recurly-create-card-token.usecase';
import { GetCardSchemaUseCase } from '../../core/domain/recurly/usecases/recurly-get-card-schema.usecase';
import { CreatePurchaseCustomUseCase } from '@/core/domain/recurly/usecases/recurly-create-purchase-custom.usecase';

const recurlyCreateCardTokenUseCaseFactory = (
    recurlyRepository: RecurlyRepository,
) => new CreateTokenCardUseCase(recurlyRepository);

const recurlyGetCardSchemaUseCaseFactory = (
    recurlyRepository: RecurlyRepository,
) => new GetCardSchemaUseCase(recurlyRepository);

const recurlyCreatePurchaseCustomUseCaseFactory = (
    recurlyRepository: RecurlyRepository,
) => new CreatePurchaseCustomUseCase(recurlyRepository);

@NgModule({
    imports: [CommonModule],
    providers: [
        {
            provide: CreateTokenCardUseCase,
            useFactory: recurlyCreateCardTokenUseCaseFactory,
            deps: [RecurlyRepository],
        },
        {
            provide: GetCardSchemaUseCase,
            useFactory: recurlyGetCardSchemaUseCaseFactory,
            deps: [RecurlyRepository],
        },
        {
            provide: CreatePurchaseCustomUseCase,
            useFactory: recurlyCreatePurchaseCustomUseCaseFactory,
            deps: [RecurlyRepository],
        },
        {
            provide: RecurlyRepository,
            useClass: RecurlyImplRepository,
        },
    ],
})
export class RecurlyDataModule {}
