import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'vg-modal-error',
    templateUrl: './modal-error.component.html',
    styleUrls: ['./modal-error.component.scss'],
})
export class ModalErrorComponent {
    @Output() closeEvent = new EventEmitter<boolean>();

    @Input() title = '';

    @Input() btnTitle = 'Cerrar';

    @Input() text = '';

    @Input() show = false;

    sendClose() {
        this.show = false;
        this.closeEvent.emit(false);
    }
}
