import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'vg-terms-conditions',
    templateUrl: './terms-conditions.component.html',
    styleUrls: ['./terms-conditions.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TermsDonditionsComponent {
    terms_conditions = [
        {
            title: 'vg_terms_conditions_title',
            info: 'vg_terms_conditions_info',
        },
    ];
}
