import { UseCase } from '@/core/base/use-case';
import { Observable } from 'rxjs';
import { AuthRepository } from '../repositories/auth.repository';

export class CheckLoginExternalStatusUseCase implements UseCase<void, boolean> {
    constructor(private readonly repository: AuthRepository) {}

    execute(): Observable<boolean> {
        return this.repository.checkLoginExternalStatus();
    }
}
