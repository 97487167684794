import { ActionReducer, INIT, UPDATE } from '@ngrx/store';
import { GlobalState } from '@/core/domain/persistence/models/global-state.model';

const LOCAL_STORAGE_GLOBAL_STATE_KEY = 'vg-data';

export const hydrationMetaReducer = (
    reducer: ActionReducer<GlobalState>,
): ActionReducer<GlobalState> => {
    return (state, action) => {
        if (action.type === INIT || action.type === UPDATE) {
            const storageValue = localStorage.getItem(
                LOCAL_STORAGE_GLOBAL_STATE_KEY,
            );
            if (storageValue) {
                try {
                    return JSON.parse(storageValue);
                } catch {
                    localStorage.removeItem(LOCAL_STORAGE_GLOBAL_STATE_KEY);
                }
            }
        }
        const nextState = reducer(state, action);
        localStorage.setItem(
            LOCAL_STORAGE_GLOBAL_STATE_KEY,
            JSON.stringify(nextState),
        );
        return nextState;
    };
};
