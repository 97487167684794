<vg-card-giftcard
    [img]="card?.design ? card?.design.url : (defaultCardDesign | cdn)"
>
    <div class="container-summary in-card">
        <div class="container-price">
            <div class="amount">
                {{ card?.amount | formatNumber }}
            </div>
            <div class="currency">MXN</div>
        </div>
        <div class="container-ellipsis">
            <div class="to">Para {{ card?.recipient }}</div>
        </div>
        <div class="from">De {{ card?.gifter }}</div>
        <div class="code-title">Código para canjear</div>
        <div class="container-code text-center">
            {{ card?.id | mask: 'AAAAA-AAAAA-AAAAA' }}
        </div>
        <div class="message font-family-lato">
            {{ card?.message }}
        </div>
    </div>
</vg-card-giftcard>
