import { getRedirectUri } from '@/common/utils/auth/redirect';
import { getLogoutUri, getPartnerRequest } from '@/common/utils/sso/sso.util';
import { isTimestampOnTime } from '@/common/utils/strings/time.utils';
import { AuthData } from '@/core/domain/auth/models/auth.data';
import { AuthRepository } from '@/core/domain/auth/repositories/auth.repository';
import { AuthApi } from '@/core/domain/auth/services/auth.api';
import { SingleSignOnApi } from '@/core/domain/auth/services/sso.api';
import { LoginUseCaseInput } from '@/core/domain/auth/usecases/login.usecase';
import { LoginExternalUseCaseInput } from '@/core/domain/auth/usecases/loginexternal.usecase';
import { ResponsePostLogin } from '@/core/domain/auth/usecases/post-login.usecase';
import { LoginExternalResponse } from '@/data/auth/services/ezy/responses/login-external.response';
import * as actions from '@/data/auth/store/auth.actions';
import { Store } from '@ngrx/store';
import { Observable, catchError, forkJoin, of, switchMap } from 'rxjs';
import { SingleSignOnLoginMapper } from '../mappers/sso-auth.mapper';
import { SingleSignOnMapper } from '../services/ezy/mappers/sso.mapper';
import { selectAuthValue } from '../store/auth.selectors';
import { AuthUserData } from '../store/models/auth.store';

export class SingleSignOnImplRepository extends AuthRepository {
    constructor(
        private readonly store: Store,
        private readonly service: SingleSignOnApi,
        private readonly serviceAuth: AuthApi,
    ) {
        super();
    }

    override loginExternal(
        input: LoginExternalUseCaseInput,
    ): Observable<LoginExternalResponse> {
        return this.service.loginExternal(input.token);
    }

    override checkLoginExternalStatus(): Observable<boolean> {
        return of(false);
    }

    override checkLogin(): Observable<AuthUserData | null> {
        return this.store.select(selectAuthValue).pipe(
            switchMap((auth) => {
                if (!auth.isLogged) {
                    throw new Error('User not logged');
                }
                return of(auth);
            }),
            switchMap((auth) => {
                if (auth.isLogged && !isTimestampOnTime(auth.expiration)) {
                    return this.restore();
                }
                return of(auth);
            }),
            catchError(() => {
                return of(null);
            }),
        );
    }

    override login(input?: LoginUseCaseInput): Observable<void> {
        const redirectUri = getRedirectUri(input?.path);
        const partnerRqst = getPartnerRequest({ redirectUri });

        window.location.href = partnerRqst;
        return of();
    }

    override logout(): Observable<void> {
        this.store.dispatch(actions.resetToken());
        const logoutUri = getLogoutUri();
        window.location.href = logoutUri;
        return of();
    }

    override postLogin(input: AuthData): Observable<ResponsePostLogin> {
        const ssoAuthLoginInput = new SingleSignOnMapper().mapFrom(input);
        return this.service.userInfo(ssoAuthLoginInput.accessToken).pipe(
            switchMap((response) => {
                const tokens = new SingleSignOnLoginMapper().mapFrom({
                    accessToken: ssoAuthLoginInput.accessToken,
                    refreshToken: ssoAuthLoginInput.refreshToken,
                    expiresIn: ssoAuthLoginInput.expiresIn,
                    details: response,
                });
                this.store.dispatch(actions.updateToken({ tokens }));
                return of({ type: 'SUCCESS', action: 'CONTINUE' });
            }),
        );
    }

    override restore(): Observable<AuthUserData | null> {
        return this.store.select(selectAuthValue).pipe(
            switchMap((auth) => {
                const refreshToken = auth.refreshToken ?? '';
                return this.service.refresh(refreshToken);
            }),
            switchMap((response) => {
                return forkJoin([
                    of(response),
                    this.service.userInfo(response.access_token),
                ]);
            }),
            switchMap(([refreshResponse, userInfoResponse]) => {
                const ssoAuthLoginInput = new SingleSignOnMapper().mapFrom({
                    access_token: refreshResponse.access_token,
                    activation_code: '',
                    expires_in: refreshResponse.expires_in,
                    id_token: refreshResponse.id_token,
                    refresh_token: refreshResponse.refresh_token,
                    scope: refreshResponse.scope,
                    state: '',
                    sub: '',
                    token_type: refreshResponse.token_type,
                    provider_type: 'SsoAuthCode',
                });

                const tokens = new SingleSignOnLoginMapper().mapFrom({
                    accessToken: ssoAuthLoginInput.accessToken,
                    refreshToken: ssoAuthLoginInput.refreshToken,
                    expiresIn: ssoAuthLoginInput.expiresIn,
                    details: userInfoResponse,
                });

                this.store.dispatch(actions.updateToken({ tokens }));
                return of(tokens);
            }),
            catchError(() => {
                this.store.dispatch(actions.resetToken());
                this.login().subscribe();
                return of(null);
            }),
        );
    }
}
