import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'vg-card-giftcard',
    templateUrl: './vg-card-giftcard.component.html',
    styleUrls: ['./vg-card-giftcard.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VgCardGiftcardComponent {
    @Input() price: string | number | null = null;
    @Input() img: string | null | undefined = null;
}
