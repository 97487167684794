import { UseCase } from '@/core/base/use-case';
import { ModalErrorData } from '@/data/modal-error/entities/modal-error-data.entity';
import { Observable } from 'rxjs';
import { ModalErrorRepository } from '../repositories/modal-error.repository';

export class ModalErrorOpenUseCase implements UseCase<ModalErrorData, never> {
    constructor(private readonly repository: ModalErrorRepository) {}

    execute(params: ModalErrorData): Observable<never> {
        return this.repository.setErrorToModal(params);
    }
}
