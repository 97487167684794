import { FeatureFlags } from '@/core/domain/configurations/models/configurations.model';
import { GetConfigParamsUseCase } from '@/core/domain/configurations/usecases/getConfigParams-loader.usecase';
import { GtmLandingDashboard } from '@/core/domain/gtm/models/gtm-landig-dashboard.models';
import { SetDataLayerParamsUseCase } from '@/core/domain/gtm/usecases/setDataLayer.usecase';
import { Component } from '@angular/core';

@Component({
    selector: 'vg-dashboard-tabs',
    templateUrl: './dashboard-tabs.component.html',
    styleUrls: ['./dashboard-tabs.component.scss'],
})
export class DashboardTabsComponent {
    maintenance = false;

    purchaseEnabled = true;

    redemptionEnabled = true;

    actionsGtm = [
        'click_home',
        'click_my_gifts',
        'click_my_transactions',
        'compensations',
    ];

    constructor(
        private readonly getFeatureFlags: GetConfigParamsUseCase<FeatureFlags>,
        private readonly gtmDataLayer: SetDataLayerParamsUseCase,
    ) {
        this.initFeatureFlags();
    }

    private initFeatureFlags(): void {
        this.getFeatureFlags.execute('featureFlags').subscribe((data) => {
            this.maintenance = data.maintenance;
            this.purchaseEnabled = data.purchaseEnabled;
            this.redemptionEnabled = data.redemptionEnabled;
        });
    }

    gtmAction(action: number) {
        this.gtmDataLayer.execute<GtmLandingDashboard>({
            event: 'ev_viva_gift_lp',
            category: 'viva_gift_header_dashboard',
            action: this.actionsGtm[action],
        });
    }
}
