import { Injectable } from '@angular/core';
import {
    EMPTY,
    Observable,
    catchError,
    concat,
    filter,
    of,
    switchMap,
    throwError,
} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
    OrdersCompensationModel,
    OrdersCompensationModelKeys,
} from '@/core/domain/orders-compensation/models/orders-compensation.model';
import { OrdersCompensationRepository } from '@/core/domain/orders-compensation/repositories/orders-compensation.repository';
import { environment as env } from '@/env/environment';
import { CheckLoginUseCase } from '@/core/domain/auth/usecases/check-login.usecase';

@Injectable({
    providedIn: 'root',
})
export class OrdersCompensationImplRepository extends OrdersCompensationRepository {
    constructor(
        private http: HttpClient,
        private readonly authCheckLogin: CheckLoginUseCase,
    ) {
        super();
    }

    override getOrdersCompensation<T>(
        config: OrdersCompensationModelKeys,
    ): Observable<T> {
        return this.authCheckLogin.execute().pipe(
            switchMap((auth) => {
                if (!auth) {
                    throwError(() => new Error('User is not logged'));
                    return of();
                }

                // TODO: MOVE THIS LOGIC INTO A SERVICE
                const headers = new HttpHeaders({
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.accessToken}`,
                    'X-Channel': 'web',
                });

                return concat(
                    //TODO: agregar funcionalidad de variables de entornos para endpoints
                    this.http.get(
                        env.vgApi.BASE_HOST +
                            env.vgApi.ENDPOINTS.getCompensations,
                        { headers: headers },
                    ),
                ).pipe(
                    filter((response: any) => response != null),
                    switchMap((response: OrdersCompensationModel) => {
                        const configData = response[config];
                        return of(configData as T);
                    }),
                    catchError(() => EMPTY),
                );
            }),
        );
    }
}
