import { CardRoutes } from '@/core/domain/configurations/models/configurations.model';
import { SetDataLayerParamsUseCase } from '@/core/domain/gtm/usecases/setDataLayer.usecase';
import { Component, Input, ViewEncapsulation } from '@angular/core';
@Component({
    selector: 'vg-swiper-routes',
    templateUrl: './vg-swiper-routes.component.html',
    styleUrls: ['./vg-swiper-routes.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VgSwiperRoutesComponent {
    @Input() route: CardRoutes = {};

    @Input() index = 0;

    constructor(private readonly gtmDataLayer: SetDataLayerParamsUseCase) {}

    public onClickContainer(): void {
        this.gtmDataLayer.execute({
            event: 'select_promotion',
            ecommerce: {
                items: [
                    {
                        item_name:
                            'Flight ticket|' +
                            this.route.nameFromIATA +
                            '-' +
                            this.route.nameToIATA,
                        item_id:
                            this.route.nameFromIATA +
                            '-' +
                            this.route.nameToIATA,
                        price: this.route.amount,
                        item_brand: 'VB',
                        item_category: 'Flight ticket',
                        item_category2:
                            this.route.nameFromIATA +
                            '-' +
                            this.route.nameToIATA,
                        item_category3: this.route.type,
                        promotion_id:
                            'viva_gift_' +
                            this.route.nameFromIATA +
                            '_' +
                            this.route.nameToIATA,
                        promotion_name: 'destinations_viva_gift',
                        creative_name: this.route.nameFrom,
                        creative_slot: '' + (this.index + 1),
                        location_id: 'hero_banner',
                        index: this.index + 1,
                        quantity: 1,
                    },
                ],
            },
        });
    }
}
