import { UseCase } from '@/core/base/use-case';
import { Observable } from 'rxjs';
import { OrdersReceivedModelKeys } from '../models/orders-received.model';
import { OrdersReceivedRepository } from '../repositories/orders-received.repository';

export type LoaderOrdersReceivedUseCaseInput = OrdersReceivedModelKeys;

export class GetOrdersReceivedParamsUseCase<T>
    implements UseCase<LoaderOrdersReceivedUseCaseInput, T>
{
    constructor(private readonly repository: OrdersReceivedRepository) {}

    execute(param: LoaderOrdersReceivedUseCaseInput): Observable<T> {
        return this.repository.getOrdersReceived<T>(param);
    }
}
