import { AuthLoginInput } from '@/core/domain/auth/models/auth-login-input.data';

export class LoginRequestBody {
    username: string;
    providerType: string;
    token: string;

    constructor(input: AuthLoginInput) {
        this.username = input.sub;
        this.providerType = input.providerType;
        this.token = input.activationCode;
    }
}
