import { RestoreUseCase } from '@/core/domain/auth/usecases/restore.usecase';
import { environment as env } from '@/env/environment';
import {
    HTTP_INTERCEPTORS,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import { Observable, catchError, of, switchMap, throwError } from 'rxjs';

/**
 * this list is a mapper from the endpoints where the VivaGift API V2
 * requires an accessToken to perform a success response
 */
const INTERCEPTOR_MAPPED_EPS = [
    // Purchase
    env.vgApi.ENDPOINTS.createPurchaseCustom,

    // Dashboard
    env.vgApi.ENDPOINTS.getCompensations,
    env.vgApi.ENDPOINTS.getPurchased,
    env.vgApi.ENDPOINTS.getReceived,

    // Redeem
    env.vgApi.ENDPOINTS.redeem,
    env.vgApi.ENDPOINTS.redeemB2B,
    env.vgApi.ENDPOINTS.redeemMP,
].map((ep) => `${env.vgApi.BASE_HOST}${ep}`);

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private readonly restoreUseCase: RestoreUseCase) {}
    intercept(
        req: HttpRequest<unknown>,
        next: HttpHandler,
    ): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                const originEndpoint = error.url ?? '';
                if (
                    error.status === 401 &&
                    INTERCEPTOR_MAPPED_EPS.includes(originEndpoint)
                ) {
                    return this.restoreUseCase.execute().pipe(
                        switchMap((auth) => {
                            const request = req.clone({
                                setHeaders: {
                                    Authorization: `Bearer ${auth?.accessToken}`,
                                },
                            });

                            return next.handle(request);
                        }),
                        catchError(() => {
                            // TODO: Define behavior when the restore endpoint fails
                            return of();
                        }),
                    );
                }

                return throwError(() => error);
            }),
        );
    }
}

export const AuthInterceptorProvider: Provider = {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
};
