import { PurchasePersistenceModel } from '../../../core/domain/purchase-persistence/models/PurchasePersistence.model';
import { createAction, props } from '@ngrx/store';

export const savePurchasePersistenceAction = createAction(
    '[PurchasePersistence] save',
    props<{ value: PurchasePersistenceModel }>(),
);
export const deletePurchasePersistenceAction = createAction(
    '[PurchasePersistence] delete',
);
