import { getRedirectUri } from '@/common/utils/auth/redirect';
import { getLogoutUri, getPartnerRequest } from '@/common/utils/sso/sso.util';
import { isTimestampOnTime } from '@/common/utils/strings/time.utils';
import { AuthData } from '@/core/domain/auth/models/auth.data';
import { AuthRepository } from '@/core/domain/auth/repositories/auth.repository';
import { AuthApi } from '@/core/domain/auth/services/auth.api';
import { SingleSignOnApi } from '@/core/domain/auth/services/sso.api';
import { LoginUseCaseInput } from '@/core/domain/auth/usecases/login.usecase';
import { LoginExternalUseCaseInput } from '@/core/domain/auth/usecases/loginexternal.usecase';
import { ResponsePostLogin } from '@/core/domain/auth/usecases/post-login.usecase';
import * as actions from '@/data/auth/store/auth.actions';
import { updateLoadingUser } from '@/data/auth/store/loading-user.actions';
import { openModalError } from '@/data/modal-error/store/modal-error.actions';
import {
    LOCAL_STORAGE_VIVA_USER_TOKEN_EXPIRES_STATE_KEY,
    LOCAL_STORAGE_VIVA_USER_TOKEN_STATE_KEY,
} from '@/data/persistence/viva-user-token-expires.reducer';
import { environment } from '@/env/environment';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { Observable, catchError, first, of, switchMap, throwError } from 'rxjs';
import { AuthMapper } from '../mappers/auth.mapper';
import { LoginMapper } from '../services/ezy/mappers/login.mapper';
import { LoginExternalResponse } from '../services/ezy/responses/login-external.response';
import { RestoreResponse } from '../services/ezy/responses/restore.response';
import { selectAuthValue } from '../store/auth.selectors';
import { updateFromSSOData } from '../store/from-sso.actions';
import { selectFromSSOState } from '../store/from-sso.selector';
import { AuthUserData } from '../store/models/auth.store';

export class AuthImplRepository extends AuthRepository {
    constructor(
        private readonly store: Store,
        private readonly service: AuthApi,
        private readonly serviceSSO: SingleSignOnApi,
        private readonly router: Router,
    ) {
        super();
    }

    override checkLoginExternalStatus(): Observable<boolean> {
        this.store.dispatch(updateLoadingUser({ value: false }));

        const vivaUserToken = localStorage.getItem(
            LOCAL_STORAGE_VIVA_USER_TOKEN_STATE_KEY,
        );

        if (vivaUserToken === null) {
            return this.store.select(selectFromSSOState).pipe(
                switchMap((isSSO) => {
                    this.store.dispatch(updateFromSSOData({ value: false }));
                    if (isSSO) {
                        return of(false);
                    }
                    return this.store.select(selectAuthValue).pipe(
                        first(),
                        switchMap((auth) => {
                            if (auth.isLogged) {
                                this.store.dispatch(actions.resetToken());
                                this.router.navigate(['/']);
                            }
                            return of(false);
                        }),
                    );
                }),
            );
        }

        //Has vivaUserToken, continue to check if is necesary recovery login
        return this.store.select(selectAuthValue).pipe(
            first(),
            switchMap((auth) => {
                if (!auth.isLogged) {
                    return this.evaluateLoginMechanism(vivaUserToken);
                }

                return of(auth);
            }),
            switchMap(
                (response: LoginExternalResponse | AuthUserData | null) => {
                    // Here the response is null because the restore request failed or login request failed
                    if (response === null) {
                        return of(null);
                    }

                    if (this.isLoginExternalResponse(response)) {
                        const tokens = new LoginMapper().mapFrom(response.data);
                        this.store.dispatch(actions.updateToken({ tokens }));
                        return of(true);
                    }

                    if (
                        this.isAuthUserData(response) &&
                        response.authToken !== vivaUserToken
                    ) {
                        return this.evaluateLoginMechanism(vivaUserToken);
                    }

                    return of(null);
                },
            ),
            switchMap((response: LoginExternalResponse | null | boolean) => {
                this.store.dispatch(updateLoadingUser({ value: false }));
                // Here the response is null because the restore request failed
                // or login request failed or success or vg user token is after
                if (response === null) {
                    return of(false);
                }

                if (this.isLoginExternalResponse(response)) {
                    const tokens = new LoginMapper().mapFrom(response.data);
                    this.store.dispatch(actions.updateToken({ tokens }));
                }

                return of(true);
            }),
        );
    }

    private openLoginModalError(): void {
        this.store.dispatch(
            openModalError({
                data: {
                    isOpen: true,
                    description: '',
                    error: '',
                    redirectSSO: true,
                },
            }),
        );
    }

    private evaluateLoginMechanism(
        vivaUserToken: string,
    ): Observable<LoginExternalResponse | null> {
        this.store.dispatch(updateLoadingUser({ value: true }));
        return this.service.restore(vivaUserToken).pipe(
            catchError(() => {
                this.openLoginModalError();
                return of(null);
            }),
            switchMap((restoreResponse: RestoreResponse | null) => {
                if (restoreResponse === null) {
                    return of(null);
                }

                if (
                    typeof restoreResponse?.data?.authTokenExternal === 'string'
                ) {
                    return this.service
                        .login({
                            activationCode:
                                restoreResponse.data.authTokenExternal,
                            sub: restoreResponse.data.userDetails.email,
                            providerType: 'SsoAccessToken',
                        })
                        .pipe(
                            catchError(() => {
                                this.openLoginModalError();
                                return of(null);
                            }),
                        );
                }

                return this.serviceSSO.loginExternal(vivaUserToken).pipe(
                    catchError(() => {
                        this.openLoginModalError();
                        return of(null);
                    }),
                    switchMap((response) => {
                        if (response != null) {
                            response.data.providerType =
                                environment.providerType;
                        }
                        return of(response);
                    }),
                );
            }),
        );
    }

    override loginExternal(
        input: LoginExternalUseCaseInput,
    ): Observable<LoginExternalResponse> {
        return this.serviceSSO.loginExternal(input.token);
    }

    private isLoginExternalResponse(
        response: any,
    ): response is LoginExternalResponse {
        return response && typeof response?.data?.authToken === 'string';
    }

    private isAuthUserData(response: any): response is AuthUserData {
        return response && typeof response.expiration === 'number';
    }

    override login(params?: LoginUseCaseInput): Observable<void> {
        const redirectUri = getRedirectUri(params?.path);
        const partnerRqst = getPartnerRequest({ redirectUri });

        window.location.href = partnerRqst;
        return of();
    }

    override postLogin(input: AuthData): Observable<ResponsePostLogin> {
        const authLoginInput = new AuthMapper().mapFrom(input);
        authLoginInput.providerType = 'SsoAuthCode';
        this.store.dispatch(updateLoadingUser({ value: true }));
        return this.service.login(authLoginInput).pipe(
            switchMap((response) => {
                const tokens = new LoginMapper().mapFrom(response.data);
                this.store.dispatch(updateLoadingUser({ value: false }));
                this.store.dispatch(actions.updateToken({ tokens }));
                return of({ type: response.type, action: response.action });
            }),
        );
    }

    override checkLogin(): Observable<AuthUserData | null> {
        return this.store.select(selectAuthValue).pipe(
            first(),
            switchMap((auth) => {
                if (!auth.isLogged) {
                    throw new Error('User not logged');
                }
                return of(auth);
            }),
            switchMap((auth) => {
                if (auth.isLogged && !isTimestampOnTime(auth.expiration)) {
                    return this.restore();
                }
                return of(auth);
            }),
            catchError(() => {
                return of(null);
            }),
        );
    }

    override restore(): Observable<AuthUserData | null> {
        return this.store.select(selectAuthValue).pipe(
            first(),
            switchMap((auth) => {
                const vivaUserTokenExpires = localStorage.getItem(
                    LOCAL_STORAGE_VIVA_USER_TOKEN_EXPIRES_STATE_KEY,
                );

                if (!(typeof vivaUserTokenExpires === 'string')) {
                    return this.service.restore(auth.authToken);
                }

                const vivaUserTokenExpiresDate =
                    DateTime.fromISO(vivaUserTokenExpires);

                const vgUserTokenExpiresDate = DateTime.fromMillis(
                    auth.expiration,
                );

                if (vivaUserTokenExpiresDate > vgUserTokenExpiresDate) {
                    const vivaUserToken = localStorage.getItem(
                        LOCAL_STORAGE_VIVA_USER_TOKEN_STATE_KEY,
                    ) as 'string';
                    return this.service.restore(vivaUserToken);
                }

                return this.service.restore(auth.authToken);
            }),
            switchMap((response) => {
                const tokens = new LoginMapper().mapFrom(response.data);
                this.store.dispatch(actions.updateToken({ tokens }));
                return of(tokens);
            }),
            catchError((error) => {
                console.log('==='.repeat(10));
                console.log('/restore');
                console.log(error);

                this.store.dispatch(actions.resetToken());
                this.login().subscribe();
                return throwError(() => error);
            }),
        );
    }

    override logout(): Observable<void | null> {
        return this.store.select(selectAuthValue).pipe(
            first(),
            switchMap((auth) => {
                this.store.dispatch(actions.resetToken());

                if (auth.providerType == environment.providerType) {
                    this.router.navigate(['/']);
                    return of(null);
                }

                const logoutUri = getLogoutUri();
                window.location.href = logoutUri;
                return of();
            }),
        );
    }
}
