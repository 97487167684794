import { Card } from '@/core/domain/orders/models/orders.model';
import { CardReceived } from '@/core/domain/orders-received/models/orders-received.model';
import { CardCompensation } from '@/core/domain/orders-compensation/models/orders-compensation.model';

import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'vg-order-status',
    templateUrl: './order-status.component.html',
    styleUrls: ['./order-status.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class OrderStatusComponent {
    @Input() title!: string;
    @Input() subTitle!: string;
    @Input() urlDetail!: string;
    @Input() cards: any = [];
    @Input() isLoading = true;
    @Input() typeDetail = '';
    // cards: any[] = [
    //     {
    //         id: 'VBUH67VWWSSLYC',
    //         date: '2024-04-15T17:30:04.832Z',
    //         expirationDate: '2025-02-26T17:30:04.832Z',
    //         gifter: 'Johanna',
    //         amount: 1000,
    //         status: 'review',
    //         thankYouMessageSent: null,
    //         recipient: 'pruebas',
    //         recipientEmail: 'testvivafan@gmail.com',
    //         design: {
    //             id: '61d8e684e3c8f72f403997e4',
    //             url: 'https://content.vivaaerobus.com/Upload-VB/vivagift/giftcards_01_site.png',
    //         },
    //         message: '¡Felicidades!',
    //     },
    // ];
}
