<!-- eslint-disable prettier/prettier -->
<button
    *ngIf="href === null"
    [disabled]="disabled"
    [ngClass]="
        styleMap[style] +
        ' ' +
        (loading ? 'loading' : '') +
        ' ' +
        (arrowAnimate ? 'arrow-button' : '')
    "
    [type]="type"
    class="btn"
>
    <ng-template [ngTemplateOutlet]="contentButton"></ng-template>
</button>
<a
    [attr.url-rendered]="href"
    *ngIf="href !== null"
    [ngClass]="
        styleMap[style] +
        ' ' +
        (loading ? 'loading' : '') +
        ' ' +
        (arrowAnimate ? 'arrow-button' : '')
    "
    class="btn"
>
    <ng-template [ngTemplateOutlet]="contentButton"></ng-template>
</a>

<ng-template #contentButton>
    <img
        [style.margin-right.px]="onlyIcon ? '0' : undefined"
        [ngClass]="onlyIcon ? 'only-icon' : ''"
        *ngIf="icon !== null && iconPosition === 'left'"
        class="btn-icon left"
        [src]="('vg/images/' + icon + '.svg') | cdn"
        alt=""
    />
    <ng-content *ngIf="!onlyIcon && !loading"></ng-content>
    <img
        [style.margin-left.px]="onlyIcon ? '0' : undefined"
        [ngClass]="onlyIcon ? 'only-icon' : ''"
        *ngIf="icon !== null && iconPosition === 'right' && !arrowAnimate"
        class="btn-icon right"
        [src]="('vg/images/' + icon + '.svg') | cdn"
        alt=""
    />
    <span *ngIf="arrowAnimate" class="arrow"></span>
    <img *ngIf="loading" class="points" alt="" />
    <div *ngIf="underline" class="underline"></div>
</ng-template>
