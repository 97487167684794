import { SetDataLayerParamsUseCase } from '@/core/domain/gtm/usecases/setDataLayer.usecase';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { GtmImplRepository } from './gtm-impl.repository';
import { GtmRepository } from '@/core/domain/gtm/repositories/gtm.repository';

const gtmLoaderUseCaseFactory = (gtmRepository: GtmRepository) =>
    new SetDataLayerParamsUseCase(gtmRepository);

@NgModule({
    imports: [
        CommonModule,
        GoogleTagManagerModule.forRoot({
            id: 'GTM-MQ2J9QHJ',
        }),
    ],
    providers: [
        {
            provide: SetDataLayerParamsUseCase,
            useFactory: gtmLoaderUseCaseFactory,
            deps: [GtmImplRepository],
        },
        {
            provide: GtmRepository,
            useClass: GtmImplRepository,
        },
    ],
})
export class GtmDataModule {}
