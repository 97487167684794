import { UseCase } from '@/core/base/use-case';
import { Observable } from 'rxjs';
import { GtmRepository } from '../repositories/gtm.repository';

export class SetDataLayerParamsUseCase implements UseCase<unknown, unknown> {
    constructor(private readonly repository: GtmRepository) {}

    execute<T extends object>(param: T): Observable<unknown> {
        return this.repository.setDataLayer<T>(param);
    }
}
