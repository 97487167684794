import { SetDataLayerParamsUseCase } from '@/core/domain/gtm/usecases/setDataLayer.usecase';
import { Directive, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[vgOnClickSendTag]',
})
export class OnClickSendTagDirective {
    @Input() vgOnClickSendTag: any | null = null;
    constructor(private readonly gtmDataLayer: SetDataLayerParamsUseCase) {}

    @HostListener('click') onClick() {
        if (this.vgOnClickSendTag === null) {
            return;
        }
        this.gtmDataLayer.execute(this.vgOnClickSendTag);
    }
}
