<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- Slider main container -->
<div class="swiper-message">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
        <!-- Slides -->
        <div
            (click)="onClickMessage('vg_purchase_message_swiper_message_1')"
            class="swiper-slide"
            [attr.value]="'vg_purchase_message_swiper_message_1' | translate"
        >
            {{ 'vg_purchase_message_swiper_message_1' | translate }}
        </div>
        <div
            (click)="onClickMessage('vg_purchase_message_swiper_message_2')"
            class="swiper-slide"
            [attr.value]="'vg_purchase_message_swiper_message_2' | translate"
        >
            {{ 'vg_purchase_message_swiper_message_2' | translate }}
        </div>
        <div
            (click)="onClickMessage('vg_purchase_message_swiper_message_3')"
            class="swiper-slide"
            [attr.value]="'vg_purchase_message_swiper_message_3' | translate"
        >
            {{ 'vg_purchase_message_swiper_message_3' | translate }}
        </div>
        <div
            (click)="onClickMessage('vg_purchase_message_swiper_message_1')"
            class="swiper-slide"
            [attr.value]="'vg_purchase_message_swiper_message_1' | translate"
        >
            {{ 'vg_purchase_message_swiper_message_1' | translate }}
        </div>
        <div
            (click)="onClickMessage('vg_purchase_message_swiper_message_2')"
            class="swiper-slide"
            [attr.value]="'vg_purchase_message_swiper_message_2' | translate"
        >
            {{ 'vg_purchase_message_swiper_message_2' | translate }}
        </div>
        <div
            (click)="onClickMessage('vg_purchase_message_swiper_message_3')"
            class="swiper-slide"
            [attr.value]="'vg_purchase_message_swiper_message_3' | translate"
        >
            {{ 'vg_purchase_message_swiper_message_3' | translate }}
        </div>
    </div>
</div>

<div class="swiper-message-next">
    <img
        [src]="'vg/images/circle-arrow-right.svg' | cdn"
        alt="arrow_green"
        class="card-redeem-img"
    />
</div>
