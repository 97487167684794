import { createReducer, on } from '@ngrx/store';
import {
    incrementAction,
    decrementAction,
    resetAction,
} from './counter.actions';

import {
    incrementReducer,
    decrementReducer,
    resetReducer,
} from './counter.reducer';
import { CounterModel } from '@/core/domain/counter/models/counter.model';

export const COUNTER_INITIAL_STATE: CounterModel = { count: 0 };
export const CounterReducer = createReducer(
    COUNTER_INITIAL_STATE,
    on(incrementAction, incrementReducer),
    on(decrementAction, decrementReducer),
    on(resetAction, resetReducer),
);
