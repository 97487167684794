import { CounterModel } from '@/core/domain/counter/models/counter.model';

export const incrementReducer = (
    state: CounterModel,
    { value }: { value: number },
): CounterModel => {
    return { count: state.count + value };
};

export const decrementReducer = (
    state: CounterModel,
    { value }: { value: number },
): CounterModel => {
    return { count: state.count - value };
};

export const resetReducer = (
    _: unknown,
    { value }: { value: number },
): CounterModel => {
    return { count: value };
};
