import { CheckLoginExternalStatusUseCase } from '@/core/domain/auth/usecases/check-loginexternal-status.usecase';
import { FeatureFlags } from '@/core/domain/configurations/models/configurations.model';
import { GetConfigParamsUseCase } from '@/core/domain/configurations/usecases/getConfigParams-loader.usecase';
import { ModalErrorModel } from '@/core/domain/modal-error/models/modal-error.model';
import { initializeStore } from '@/data/app/store/app.actions';
import { updateRefreshData } from '@/data/auth/store/refresh-data.actions';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { switchMap } from 'rxjs';
import { TranslationsGetLanguageUseCase } from '../core/domain/translation/usecases/translations-get-language.usecase';
import { TranslationsLoaderUseCase } from '../core/domain/translation/usecases/translations-loader.usecase';

@Component({
    selector: 'vg-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    maintenance = false;
    isOpenModal = false;

    titlePage = 'Cómo canjear tu Viva Gift';
    subTitle = 'Cómo canjear tu Viva Gift';
    page = 'dashboard';
    dataTest = [
        {
            title: 'Lorem ipsum',
            image: 'https://content.vivaaerobus.com/Upload-VB/vivagift/ilustracionlp11.svg',
            text: 'Crea tu tarjeta de regalo, elige el monto y personalízala',
        },
        {
            title: 'Lorem ipsum',
            image: 'https://content.vivaaerobus.com/Upload-VB/vivagift/ilustracionlp11.svg',
            text: 'Envíala por correo al destinatario',
        },
        {
            title: 'Lorem ipsum',
            image: 'https://content.vivaaerobus.com/Upload-VB/vivagift/ilustracionlp11.svg',
            text: 'Regala un viaje a cualquier destino de Viva Aerobus',
        },
        {
            title: 'Lorem ipsum',
            image: 'https://content.vivaaerobus.com/Upload-VB/vivagift/ilustracionlp11.svg',
            text: 'Regala un viaje a cualquier destino de Viva Aerobus',
        },
    ];

    titlePageRedeem = '¿Tienes un código Viva Gift?';
    subtitlePageRedeem = 'Canjealo para convertirlo en Viva Cash';
    btnTitlePageRedeem = 'Canjear una tarjeta de regalo';
    imageRedeem = 'assets/images/solicita_tu_cotizacion.svg';

    // vg-modal-success
    titleModalSuccess = 'Tarjeta Viva Gift';
    subtitleModalSuccess = 'Envío el mismo día';
    textModalSuccess =
        'Lorem ipsum dolor sit amet consecteur Lorem ipsum dolor sit amet consecteur Lorem ipsum dolor sit amet consecteur.';
    btnTitleModalSuccess = 'Entendido';

    //vg-modal-error
    titleModalError = 'Error';
    textModalError = 'El código de la tarjeta de regalo no fue encontrado.';
    btnTitleModalError = 'Entendido';

    titleFaq = 'Lorem ipsum dolor sit amet';
    infoFaq =
        'Lorem ipsum dolor sit amet consecteur Lorem ipsum dolor sit amet consecteur Lorem ipsum dolor sit amet consecteur.';

    constructor(
        private readonly store: Store<{ modalError: ModalErrorModel }>,
        private readonly translationsLoaderUseCase: TranslationsLoaderUseCase,
        private readonly getLanguageUseCase: TranslationsGetLanguageUseCase,
        private readonly checkLoginExternalStatusUseCase: CheckLoginExternalStatusUseCase,
        private readonly getFeatureFlags: GetConfigParamsUseCase<FeatureFlags>,
        private renderer: Renderer2,
    ) {
        this.store.dispatch(initializeStore());
    }

    ngOnInit(): void {
        this.initFeatureFlags();
        this.initTranslations();
        this.initModalError();
        this.initListenerFocusTab();
    }

    private initListenerFocusTab(): void {
        this.renderer.listen('window', 'focus', () => {
            this.store.dispatch(updateRefreshData({ value: true }));
        });
        this.renderer.listen('window', 'blur', () => {
            this.store.dispatch(updateRefreshData({ value: false }));
        });
    }

    private initFeatureFlags(): void {
        this.getFeatureFlags.execute('featureFlags').subscribe((data) => {
            this.maintenance = data.maintenance;
        });
    }

    private initTranslations(): void {
        this.getLanguageUseCase
            .execute()
            .pipe(
                switchMap((langItem) => {
                    return this.translationsLoaderUseCase.execute({
                        lang: langItem.language,
                        reload: true,
                    });
                }),
            )
            .subscribe();
    }

    private initModalError() {
        this.store.select('modalError').subscribe((modal) => {
            this.isOpenModal = modal.isOpen;
        });
    }
}
