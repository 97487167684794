import { GtmLandingDashboard } from '@/core/domain/gtm/models/gtm-landig-dashboard.models';
import { SetDataLayerParamsUseCase } from '@/core/domain/gtm/usecases/setDataLayer.usecase';
import { Component } from '@angular/core';

@Component({
    selector: 'vg-swiper-attractions',
    templateUrl: './swiper-attractions.component.html',
    styleUrls: ['./swiper-attractions.component.scss'],
})
export class SwiperAttractionsComponent {
    image = '/vg/dashboard/banner_plane.svg';

    image2 = '/vg/dashboard/banner_beneficios_adicionales.svg';

    constructor(private readonly gtmDataLayer: SetDataLayerParamsUseCase) {}

    gtmAction(action: string) {
        this.gtmDataLayer.execute<GtmLandingDashboard>({
            event: 'ev_viva_gift_lp',
            category: 'viva_gift_header_dashboard',
            action: action,
        });
    }
}
