import { Pipe, PipeTransform } from '@angular/core';
import { CdnService } from '../../../common/cdn/cdn.service';

@Pipe({
    name: 'cdn',
})
export class CdnPipe implements PipeTransform {
    transform(resource: string): string {
        return CdnService.url(resource);
    }
}
