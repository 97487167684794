import { LoginExternalResponse } from '@/data/auth/services/ezy/responses/login-external.response';
import { AuthUserData } from '@/data/auth/store/models/auth.store';
import { Observable } from 'rxjs';
import { AuthData } from '../models/auth.data';
import { LoginUseCaseInput } from '../usecases/login.usecase';
import { LoginExternalUseCaseInput } from '../usecases/loginexternal.usecase';
import { ResponsePostLogin } from '../usecases/post-login.usecase';

export abstract class AuthRepository {
    abstract checkLogin(): Observable<AuthUserData | null>;
    abstract login(input?: LoginUseCaseInput): Observable<void>;
    abstract logout(): Observable<void | null>;
    abstract postLogin(input: AuthData): Observable<ResponsePostLogin>;
    abstract restore(): Observable<AuthUserData | null>;
    abstract loginExternal(
        input: LoginExternalUseCaseInput,
    ): Observable<LoginExternalResponse>;
    abstract checkLoginExternalStatus(): Observable<boolean>;
}
