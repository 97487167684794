import { LoginExternalResponse } from '@/data/auth/services/ezy/responses/login-external.response';
import { UserInfoSuccessResponse } from '@/data/auth/services/sso/responses/get-user-info.response';
import { RefreshTokenResponse } from '@/data/auth/services/sso/responses/refresh-token.response';
import { Observable } from 'rxjs';

export abstract class SingleSignOnApi {
    abstract userInfo(accessToken: string): Observable<UserInfoSuccessResponse>;
    abstract refresh(refreshToken: string): Observable<RefreshTokenResponse>;
    abstract loginExternal(token: string): Observable<LoginExternalResponse>;
}
