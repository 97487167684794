<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="dashboard-tabs-container position-fixed end-0">
    <div
        class="dashboard-tabs mx-auto d-flex align-items-end position-relative"
    >
        <ul class="d-flex justify-content-center align-items-start m-0 p-0">
            <li
                id="d-menu-home"
                [routerLink]="'/dashboard/home' | formatUrl | async"
                (click)="gtmAction(0)"
            >
                <a>
                    <img
                        [src]="'vg/images/dashboard-home.svg' | cdn"
                        alt=""
                    /><span>
                        {{ 'vg_landing_dashboard_tabs-start' | translate }}
                    </span>
                </a>
            </li>
            <li
                id="d-menu-gifts"
                [routerLink]="'/dashboard/gifts' | formatUrl | async"
                (click)="gtmAction(1)"
            >
                <a>
                    <img [src]="'vg/images/dashboard-gifts.svg' | cdn" alt="" />
                    <span>
                        {{ 'vg_landing_dashboard_tabs-my-gifts' | translate }}
                    </span>
                </a>
            </li>
            <li
                id="d-menu-orders"
                [ngClass]="{ disabled: !purchaseEnabled }"
                (click)="gtmAction(2)"
                [routerLink]="
                    (purchaseEnabled ? '/dashboard/orders' : '/dashboard/home')
                        | formatUrl
                        | async
                "
            >
                <a>
                    <img [src]="'vg/images/dashboard-shopping.svg' | cdn" alt="" />
                    <span>
                        {{
                            'vg_landing_dashboard_tabs-my-shopping' | translate
                        }}
                    </span>
                </a>
            </li>
            <li
                id="d-menu-compensations"
                (click)="gtmAction(3)"
                [routerLink]="'/dashboard/compensations' | formatUrl | async"
            >
                <a>
                    <img [src]="'vg/images/dashboard-vc.svg' | cdn" alt="" />
                    <span>
                        {{
                            'vg_landing_dashboard_tabs-compensations'
                                | translate
                        }}
                    </span>
                </a>
            </li>
        </ul>
    </div>
</div>
