import { Component, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { noop } from 'rxjs';

@Component({
    selector: 'vg-check',
    templateUrl: './vg-check.component.html',
    styleUrls: ['./vg-check.component.scss'],
})
export class VgCheckComponent implements ControlValueAccessor {
    @Input() for: string | null = null;
    //The internal data model
    private innerValue: unknown = null;
    //Placeholders for the callbacks which are later provided
    //by the Control Value Accessor
    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: unknown) => void = noop;

    constructor(@Self() @Optional() public ngControl: NgControl) {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    //get accessor
    public get value(): unknown {
        return this.innerValue;
    }

    //set accessor including call the onchange callback
    public set value(v: unknown) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }

    //Set touched on blur
    public onBlur() {
        this.onTouchedCallback();
    }

    //From ControlValueAccessor interface
    public writeValue(value: unknown) {
        if (value !== this.innerValue) {
            this.innerValue =
                typeof value === 'string' && value.length === 0 ? null : value;
        }
    }

    //From ControlValueAccessor interface
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    //From ControlValueAccessor interface
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }
}
