import { Injectable } from '@angular/core';
import {
    EMPTY,
    Observable,
    catchError,
    concat,
    filter,
    first,
    of,
    switchMap,
} from 'rxjs';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { ConfigurationsRepository } from '@/core/domain/configurations/repositories/configurarions.repository';
import {
    ConfigurationsModel,
    ConfigurationsModelKeys,
} from '@/core/domain/configurations/models/configurations.model';
import { CdnService } from '@/common/cdn/cdn.service';
import { loadAction } from './store/configuration.actions';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationImplRepository extends ConfigurationsRepository {
    constructor(
        // eslint-disable-next-line @ngrx/no-typed-global-store
        private readonly store: Store<{ configuration: ConfigurationsModel }>,
        private readonly http: HttpClient,
    ) {
        super();
    }

    override getConfig<T>(config: ConfigurationsModelKeys): Observable<T> {
        return concat(
            // eslint-disable-next-line @ngrx/prefer-selector-in-select
            this.store.select('configuration').pipe(first()),
            this.http.get(CdnService.url('/vg/configs.json')),
        ).pipe(
            filter(
                (response) =>
                    Object.keys(response).length > 0 &&
                    response.constructor === Object,
            ),
            switchMap((response: ConfigurationsModel) => {
                const configData = response[config];
                this.store.dispatch(loadAction({ value: response }));
                return of(configData as T);
            }),
            catchError(() => EMPTY),
        );
    }
}
