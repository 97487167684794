<vg-modal-wrapper>
    <div
        class="modal fade show"
        id=""
        tabindex="-1"
        aria-modal="true"
        role="dialog"
        style="display: block"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header position-relative">
                    <div class="modal-text-container">
                        <p
                            class="fs-6 text-vg-green mb-0 fw-bold font-family-axiforma"
                        >
                            {{
                                isCompensation
                                    ? ('vg_redeem_modal_success_is_compensation-title'
                                      | translate)
                                    : ('vg_redeem_modal_success_not_compensation-title'
                                      | translate)
                            }}
                        </p>
                        <p
                            class="fs-3 text-light mb-0 fw-bold font-family-axiforma"
                        >
                            {{
                                isCompensation
                                    ? ('vg_redeem_modal_success_is_compensation-subtitle'
                                      | translate)
                                    : ('vg_redeem_modal_success_not_compensation-subtitle'
                                      | translate)
                            }}
                        </p>
                    </div>
                    <button
                        type="button"
                        class="position-absolute rounded-circle border-0"
                        data-bs-dismiss="modal"
                        (click)="sendClose()"
                    >
                        <img [src]="'vg/images/close-white.svg' | cdn" alt="" />
                    </button>
                </div>
                <div
                    class="modal-body d-flex justify-content-center flex-column"
                >
                    <p class="font-family-lato lh-1">
                        {{
                            isCompensation
                                ? ('vg_redeem_modal_success_is_compensation-text'
                                  | translate)
                                : ('vg_redeem_modal_success_not_compensation-text'
                                  | translate)
                        }}
                    </p>

                    <p *ngIf="!isCompensation" class="font-family-lato lh-1">
                        {{
                            'vg_redeem_modal_success_not_compensation-description1'
                                | translate
                        }}
                        <br />
                        {{
                            'vg_redeem_modal_success_not_compensation-description2'
                                | translate
                        }}
                    </p>

                    <p *ngIf="isCompensation" class="font-family-lato lh-1">
                        {{
                            'vg_redeem_modal_success_is_compensation-description1'
                                | translate
                        }}
                        {{ expirationDateFormatted }}<br />
                        {{
                            'vg_redeem_modal_success_is_compensation-description2'
                                | translate
                        }}
                    </p>
                    <vg-button
                        [style]="'success-outline'"
                        (click)="sendSuccess()"
                        class="w-100 d-grid"
                    >
                        {{
                            isCompensation
                                ? ('vg_redeem_modal_success_is_compensation-button-title'
                                  | translate)
                                : ('vg_redeem_modal_success_not_compensation-button-title'
                                  | translate)
                        }}
                    </vg-button>
                </div>
            </div>
        </div>
    </div>
</vg-modal-wrapper>
