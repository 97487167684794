import { createReducer, on } from '@ngrx/store';
import { updateFromSSOData } from './from-sso.actions';

export const updateFromSSOReducer = (
    _: boolean,
    { value }: { value: boolean },
): boolean => value;

export const FromSSOReducer = createReducer(
    false,
    on(updateFromSSOData, updateFromSSOReducer),
);
