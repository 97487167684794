<a
    (click)="onClickContainer()"
    [href]="route.href | singleTranslation"
    target="_blank"
    class="container-route"
>
    <vg-card class="vg-card-route">
        <img class="card-img" [src]="route.img" alt="" />
        <div class="container-info-route">
            <div class="container">
                <div class="row row-container-info-route">
                    <div class="col">
                        <h3 class="name-route">
                            <span>{{ route?.nameFrom }} -</span>
                            {{ route?.nameTo }}
                        </h3>
                    </div>
                    <div class="col col-md-auto">
                        <div class="row">
                            <div
                                [ngClass]="route?.off !== '0' ? 'off' : ''"
                                class="col col-container-info-route-amount"
                            >
                                {{ route?.amount | formatNumber: '' }}
                                <span>$</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-prices">
                                <div *ngIf="route?.off !== '0'" class="off">
                                    {{ route?.off }}
                                </div>
                                <div>MXN /</div>
                                <img
                                    [src]="'vg/images/airplane.svg' | cdn"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr class="seperation-info-route" />
        <div class="container-btn-route">
            <div class="container">
                <div class="row">
                    <div class="col title">
                        <div>
                            {{
                                'vg_routes_vg_swiper_routes_div_btn-link'
                                    | translate
                            }}
                        </div>
                    </div>
                    <div class="col col-md-auto btn-route">
                        <vg-button
                            [onlyIcon]="true"
                            [icon]="'next-arrow-dark'"
                            [style]="'link'"
                        ></vg-button>
                    </div>
                </div>
            </div>
        </div>
    </vg-card>
</a>
