import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'vg-card-giftcard-design',
    templateUrl: './vg-card-giftcard-design.component.html',
    styleUrls: ['./vg-card-giftcard-design.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VgCardGiftcardDesignComponent {
    @Input() img = '';
    @Input() title = '¡Feliz aniversario!';
    @Input() price: string | number | null = null;
}
