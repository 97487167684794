import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    Component,
    EventEmitter,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { CardSchema } from '../../../../core/domain/recurly/models/CardSchema';
import { ValidationService } from '../../../../common/validation/validation.service';
import { GetPurchaseUseCase } from '@/core/domain/purchase-persistence/usecases/get-purchase-persistence.usecase';
import { PurchasePersistenceModel } from '@/core/domain/purchase-persistence/models/PurchasePersistence.model';
import { ANIMATIONS_CARD } from '../card-amount/card-amount.component';

@Component({
    selector: 'vg-card-payment',
    templateUrl: './vg-card-payment.component.html',
    styleUrls: ['./vg-card-payment.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: ANIMATIONS_CARD,
})
export class VgCardPaymentComponent {
    public paymentForm: FormGroup;
    public paymentInformation = {
        cardNumber: '',
    };
    public cardSchema: CardSchema = {
        bin: '',
        scheme: '',
    };
    public purchasePersistence: PurchasePersistenceModel | null = null;
    public state = null;
    //TODO: cambiar a contenido dinámico
    public states = [
        {
            id: 'Aguascalientes',
            name: 'Aguascalientes',
        },
        {
            id: 'Baja California',
            name: 'Baja California',
        },
        {
            id: 'Baja California Sur',
            name: 'Baja California Sur',
        },
        {
            id: 'Campeche',
            name: 'Campeche',
        },
        {
            id: 'Chiapas',
            name: 'Chiapas',
        },
        {
            id: 'Chihuahua',
            name: 'Chihuahua',
        },
        {
            id: 'Ciudad de México',
            name: 'Ciudad de México',
        },
        {
            id: 'Coahuila',
            name: 'Coahuila',
        },
        {
            id: 'Colima',
            name: 'Colima',
        },
        {
            id: 'Durango',
            name: 'Durango',
        },
        {
            id: 'Estado de México',
            name: 'Estado de México',
        },
        {
            id: 'Guanajuato',
            name: 'Guanajuato',
        },
        {
            id: 'Guerrero',
            name: 'Guerrero',
        },
        {
            id: 'Hidalgo',
            name: 'Hidalgo',
        },
        {
            id: 'Jalisco',
            name: 'Jalisco',
        },
        {
            id: 'Michoacán',
            name: 'Michoacán',
        },
        {
            id: 'Morelos',
            name: 'Morelos',
        },
        {
            id: 'Nayarit',
            name: 'Nayarit',
        },
        {
            id: 'Nuevo León',
            name: 'Nuevo León',
        },
        {
            id: 'Oaxaca',
            name: 'Oaxaca',
        },
        {
            id: 'Puebla',
            name: 'Puebla',
        },
        {
            id: 'Querétaro',
            name: 'Querétaro',
        },
        {
            id: 'Quintana Roo',
            name: 'Quintana Roo',
        },
        {
            id: 'San Luis Potosí',
            name: 'San Luis Potosí',
        },
        {
            id: 'Sinaloa',
            name: 'Sinaloa',
        },
        {
            id: 'Sonora',
            name: 'Sonora',
        },
        {
            id: 'Tabasco',
            name: 'Tabasco',
        },
        {
            id: 'Tamaulipas',
            name: 'Tamaulipas',
        },
        {
            id: 'Tlaxcala',
            name: 'Tlaxcala',
        },
        {
            id: 'Veracruz',
            name: 'Veracruz',
        },
        {
            id: 'Yucatán',
            name: 'Yucatán',
        },
        {
            id: 'Zacatecas',
            name: 'Zacatecas',
        },
    ];
    @Output() changes = new EventEmitter();
    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly validationService: ValidationService,
        private readonly getPurchasePersistence: GetPurchaseUseCase,
    ) {
        this.paymentForm = this.formBuilder.group({
            cardNumber: ['', Validators.compose([Validators.required])],
            expirationDate: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(4),
                ]),
            ],
            cvv: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(3),
                ]),
            ],
            firstName: [
                '',
                Validators.compose([
                    Validators.required,
                    this.validationService.withoutSpecialCharNumber,
                ]),
            ],
            lastName: [
                '',
                Validators.compose([
                    Validators.required,
                    this.validationService.withoutSpecialCharNumber,
                ]),
            ],
            address: ['', Validators.compose([Validators.required])],
            city: ['', Validators.compose([Validators.required])],
            state: ['', Validators.compose([Validators.required])],
            postalCode: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(5),
                ]),
            ],
        });
        this.paymentForm.statusChanges.subscribe(() =>
            this.changes.emit({
                valid: this.paymentForm.valid,
                data: this.paymentForm.value,
            }),
        );
    }

    public initPurchasePersistence(): void {
        this.getPurchasePersistence
            .execute()
            .subscribe((purchasePersistence) => {
                this.purchasePersistence = purchasePersistence;
            });
    }

    public onGetCardSchema(cardSchema: CardSchema) {
        this.cardSchema = cardSchema;
        if (
            this.cardSchema.scheme !== 'american-express' &&
            this.cardSchema.scheme.length > 0
        ) {
            this.paymentForm
                .get('cardNumber')
                ?.setValidators([
                    Validators.required,
                    Validators.minLength(16),
                ]);
            this.paymentForm.get('cardNumber')?.updateValueAndValidity();
        }
        if (this.cardSchema.scheme === 'american-express') {
            this.paymentForm
                .get('cardNumber')
                ?.setValidators([
                    Validators.required,
                    Validators.minLength(15),
                ]);
            this.paymentForm.get('cardNumber')?.updateValueAndValidity();
        }
    }
}
