<h2 class="title-h2 fw-bold">
    {{ 'vg_landing_purchase_custom_h2_title' | translate }}
</h2>
<div class="gc-check" [@fadeInOut]>
    <vg-card>
        <div class="col-12 container-all">
            <div class="col-12 d-flex flex-column">
                <div class="edit-gc d-flex flex-column">
                    <div
                        class="step-info d-flex justify-content-between align-items-center w-100"
                    >
                        <div class="step-title d-flex align-items-center">
                            <img
                                [src]="'vg/images/check-circle.svg' | cdn"
                                alt="check-circle"
                            />
                            <span class="fw-bold">
                                {{
                                    'vg_landing_purchase_custom_gift-for-text'
                                        | translate
                                }}
                                {{ purchasePersistence?.amount | formatNumber }}
                            </span>
                        </div>
                        <a
                            [routerLink]="'purchase/amount' | formatUrl | async"
                            class="edit-btn"
                            (click)="gtmAction('gift_amount')"
                        >
                            {{
                                'vg_landing_purchase_custom_btn-link-edit'
                                    | translate
                            }}
                        </a>
                    </div>
                    <span class="gray-text font-family-lato">
                        {{
                            'vg_landing_purchase_custom_balance-text'
                                | translate
                        }}
                    </span>
                </div>

                <div class="edit-gc d-flex flex-column">
                    <div
                        class="step-info d-flex justify-content-between align-items-center w-100"
                    >
                        <div class="step-title d-flex align-items-center">
                            <img
                                [src]="'vg/images/check-circle.svg' | cdn"
                                alt="check-circle"
                            />
                            <span class="fw-bold">
                                {{
                                    'vg_landing_purchase_custom_for-text'
                                        | translate
                                }}
                                {{
                                    purchasePersistence?.shipment.recipientName
                                }}
                            </span>
                        </div>
                        <a
                            [routerLink]="'purchase/send' | formatUrl | async"
                            class="edit-btn"
                            (click)="gtmAction('email_information')"
                        >
                            {{
                                'vg_landing_purchase_custom_btn-link-edit'
                                    | translate
                            }}
                        </a>
                    </div>
                    <span class="gray-text font-family-lato"
                        >{{ purchasePersistence?.shipment.recipientEmail }}
                        {{
                            'vg_landing_purchase_custom_receive-it-text'
                                | translate
                        }}
                        {{
                            purchasePersistence?.shipment
                                ?.recipientScheduleShipping
                                | date: 'dd MMMM YYYY' : undefined : lang
                        }}</span
                    >
                </div>

                <div class="edit-gc d-flex flex-column">
                    <div
                        class="step-info d-flex justify-content-between align-items-center w-100"
                    >
                        <div class="step-title d-flex align-items-center">
                            <img
                                [src]="'vg/images/check-circle.svg' | cdn"
                                alt="check-circle"
                            />
                            <span class="fw-bold">
                                {{
                                    'vg_landing_purchase_custom_of-text'
                                        | translate
                                }}
                                {{ purchasePersistence?.message?.from }}
                            </span>
                        </div>
                        <a
                            [routerLink]="
                                'purchase/message' | formatUrl | async
                            "
                            (click)="gtmAction('name_and_message')"
                            class="edit-btn"
                        >
                            {{
                                'vg_landing_purchase_custom_btn-link-edit'
                                    | translate
                            }}
                        </a>
                    </div>
                    <span class="gray-text font-family-lato">{{
                        purchasePersistence?.message?.message
                    }}</span>
                </div>
            </div>

            <div class="customize-btn-container">
                <vg-button
                    (click)="goToLogin()"
                    [style]="'success-outline'"
                    class="w-100 d-grid"
                >
                    {{
                        (isUserLogged
                            ? 'vg_landing_purchase_amount_btn_next'
                            : 'vg_landing_purchase_custom_btn_login'
                        ) | translate
                    }}
                </vg-button>
            </div>
        </div>
    </vg-card>
</div>
