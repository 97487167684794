import { Component, Input } from '@angular/core';

export type BTN_STYLE =
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark'
    | 'disabled'
    | 'link';

@Component({
    selector: 'vg-badge',
    templateUrl: './vg-badge.component.html',
    styleUrls: ['./vg-badge.component.scss'],
})
export class VgBadgeComponent {
    public styleMap = {
        primary: 'bg-primary',
        secondary: 'bg-secondary',
        tertiary: 'bg-tertiary',
        success: 'bg-success',
        danger: 'bg-danger',
        warning: 'bg-warning',
        info: 'bg-info',
        light: 'bg-light',
        dark: 'bg-dark',
        link: 'bg-link',
        disabled: 'bg-disabled',
    };
    @Input() style: BTN_STYLE = 'primary';
}
