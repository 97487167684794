import { createReducer, on } from '@ngrx/store';
import { setProgressAction } from './current-progress.actions';
import { setProgressReducer } from './current-progress.reducer';

export const PROGRESS_INITIAL_STATE = 'design';

export const CurrentProgressReducer = createReducer(
    PROGRESS_INITIAL_STATE,
    on(setProgressAction, setProgressReducer),
);
