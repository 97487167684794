import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import Swiper from 'swiper';
import { EffectFade, Navigation, Pagination } from 'swiper/modules';

@Component({
    selector: 'vg-swiper',
    templateUrl: './swiper.component.html',
    styleUrls: ['./swiper.component.scss'],
})
export class SwiperComponent implements AfterViewInit {
    @Input() type!: string;

    @Input() loop = false;
    @Input() slidesPerView = 1;
    @Input() spaceBetween = '0px';
    @Input() centeredSlides = false;
    @Input() swiperButtonNext = '.swiper-button-next';
    @Input() swiperButtonPrev = '.swiper-button-prev';
    @Input() showPagination = true;
    @Input() showBtnNavigation = true;
    @Input() breakpoints = {};
    @Input() initialSlide = 0;

    @Output() currentSlideIndex = new EventEmitter<number>();

    public uniqClass = Math.random().toString(36).slice(2);

    private $swiper: Swiper | undefined;

    ngAfterViewInit() {
        this.initSwiper();
    }

    initSwiper(): void {
        let modulesSwiper = [];
        modulesSwiper = this.showPagination
            ? [Navigation, Pagination, EffectFade]
            : [Navigation];

        this.$swiper = new Swiper('.swiper-' + this.uniqClass, {
            modules: modulesSwiper,
            direction: 'horizontal',
            initialSlide: this.initialSlide,
            loop: this.loop,
            slidesPerView: this.slidesPerView,
            spaceBetween: this.spaceBetween,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            centeredSlides: this.centeredSlides,
            navigation: {
                nextEl: this.swiperButtonNext,
                prevEl: this.swiperButtonPrev,
            },
            breakpoints: this.breakpoints,
            speed: 200,
            effect: 'fade',
            fadeEffect: {
                crossFade: true,
            },
            on: {
                activeIndexChange: () => {
                    this.onChangeSlide();
                },
            },
        });
    }

    public onChangeSlide() {
        if (this.$swiper?.activeIndex) {
            this.currentSlideIndex.emit(this.$swiper.realIndex);
        }
    }
}
