import { Component, Input } from '@angular/core';

export type PROGRESS_NAME =
    | 'design'
    | 'amount'
    | 'shipment'
    | 'personalization'
    | 'message'
    | 'payment'
    | 'confirmation';

@Component({
    selector: 'vg-progress',
    templateUrl: './vg-progress.component.html',
    styleUrls: ['./vg-progress.component.scss'],
})
export class VgProgressComponent {
    @Input() current: PROGRESS_NAME = 'design';
}
