import { environment as env } from '@/env/environment';

export const getBasicAuthorization = () => {
    const clientId = env.SSO.PARTNER.clientId;
    const clientSecret = env.SSO.PARTNER.clientSecret;

    const rawBasicValue = `${clientId}:${clientSecret}`;

    return btoa(rawBasicValue);
};
